<template>
  <section id="faq" class="faq section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>{{ $t('faq.titulo') }}</h2>        
      </div>

      <div class="faq-list">
        <ul>          
          <li data-aos="fade-up" data-aos-delay="100">            
            <p data-bs-target="#faq-list-1">
              <b>{{ $t('faq.p_1t') }}</b>
              <i class="bx bx-chevron-down icon-show"></i>
              <i class="bx bx-chevron-up icon-close"></i>
            </p>
            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
              <p>
                {{ $t('faq.p_1') }}
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="400">
              <b>{{ $t('faq.p_2t') }}</b>
            <div id="faq-list-2" data-bs-parent=".faq-list">
              <p>
                {{ $t('faq.p_2') }}
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="200">
            <i class="bx bx-help-circle icon-help"></i> <p data-bs-toggle="collapse" data-bs-target="#faq-list-3">
              <b>{{ $t('faq.p_3t') }}</b></p>
            <div id="faq-list-3" data-bs-parent=".faq-list">
              <p>
                {{ $t('faq.p_3') }}
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <p data-bs-toggle="collapse" data-bs-target="#faq-list-4">
              <b>{{ $t('faq.p_4t') }}</b></p>
            <div id="faq-list-4" data-bs-parent=".faq-list">
              <p>
                {{ $t('faq.p_4') }}
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <p data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">
              <b>{{ $t('faq.p_5t') }}</b></p>
            <div id="faq-list-5" data-bs-parent=".faq-list">
              <p>
                {{ $t('faq.p_5') }}
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <p data-bs-toggle="collapse" data-bs-target="#faq-list-6" class="collapsed">
              <b>{{ $t('faq.p_6t') }}</b></p>
            <div id="faq-list-6" data-bs-parent=".faq-list">
              <p>
                {{ $t('faq.p_6') }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "FaqComponent",
  };
</script>

<style scoped>
  .faq .faq-list {
    padding: 0 100px;
  }
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  .faq .faq-list li + li {
    margin-top: 15px;
  }
  .faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
  }
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #006283;
  }
  .faq .faq-list .icon-show, .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  .faq .faq-list .icon-show {
    display: none;
  }
  .faq .faq-list a.collapsed {
    color: #37517e;
    transition: 0.3s;
  }
  .faq .faq-list a.collapsed:hover {
    color: #006283;
  }
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
</style>