<template>
<div class="col-lg-4 col-md-6 portfolio-item" :class="filter">
  <div class="portfolio-img">
    <button type="button" class="btn btn-primary portfolio_video_btn"><i class="bi bi-play-circle"></i>{{ title }}</button>
    <video controls style="display: none">
      <source :src="video" type="video/mp4" class="portfolio_video_source">
      Seu navegador não suporta vídeo.
    </video>
  </div>
</div>
  
</template>

<script>
  export default {
    name: "PortfoliovideoComponent",
    props: ['filter', 'title', 'video'],
    mounted() {
      var modal = document.getElementById('modal_video_div');
      var header = document.getElementById("header");
      var goTop = document.getElementById("goTop");
      var modalVideo = document.getElementById("modal_video");
      var modalVideoBtns = document.querySelectorAll(".portfolio_video_btn");      

      modalVideoBtns.forEach(function(el) {
        el.addEventListener('click', async function() {
          header.style.display = "none";
          goTop.style.display = "none";
          modal.style.display = "block";          
          var pl = this.parentElement;          
          var modalVideoSrc = document.getElementById("modal_video_source");      
          modalVideoSrc.src = pl.querySelector(".portfolio_video_source").src;                    
          modalVideo.load();
          await new Promise(r => setTimeout(r, 2000));
          modalVideo.play();
        });
      });

      // Qdo clica no <span> (x) ou imagem, fecha o modal
      var closeClick = function() {
        header.style.display = "block";
        goTop.style.display = "block";
        modal.style.display = "none";
        modalVideo.pause();
        modalVideo.currentTime = 0;
      }
      
      var span = document.getElementById("close-modal-video");
      span.onclick = closeClick;
    }
  };  
</script>