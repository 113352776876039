<template>
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>{{ $t('contato.titulo') }}</h2>
        <p>{{ $t('contato.desc') }}</p>
      </div>

      <div class="row">
        <!-- <div class="col-lg-5 d-flex align-items-stretch">         -->
          <div class="col-lg-4">
            <h2>
              <country-flag 
                country='br' 
                size='big'/>
              {{ $t('contato.brasil') }}
            </h2>
            <div class="address">
              <h4>
                <i class="bi bi-geo-alt">
                  <b-icon-geo-alt></b-icon-geo-alt>
                </i>
                {{ $t('contato.endereco_titulo') }}
              </h4>
              <p>Rua John Harrison, 299 - CJ 606, CEP 05074-080, Lapa, Sâo Paulo</p>
            </div>

            <div class="email">
              <h4>
                <i class="bi bi-envelope">
                  <b-icon-envelope></b-icon-envelope>
                </i>
                Email:
              </h4>
              <p><a href="mailto:contato@rt1.com.br">contato@rt1.com.br</a></p>
            </div>
          </div>

            <!-- <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Telefone:</h4>
              <p>+55 11 96414-2626</p>
            </div> -->

          <!-- <div class="col-lg-4">              
            <h2>
              <country-flag 
                country='us' 
                size='big'/>
              {{ $t('contato.eua') }}
            </h2>
            <div class="address">
              <h4>
                <i class="bi bi-geo-alt">
                  <b-icon-geo-alt></b-icon-geo-alt>
                </i>
                {{ $t('contato.endereco_titulo') }}
              </h4>
              <p>2059 CAMDEN AVE SPC 401 San Jose, CA 95124</p>
            </div>

            <div class="email">
              <h4>
                <i class="bi bi-envelope">
                  <b-icon-envelope></b-icon-envelope>
                </i>
                Email:
              </h4>
              <p><a href="mailto:fpalamone@rt-one.com">fpalamone@rt-one.com</a></p>
            </div>
          </div> -->

            <!-- <div class="phone">
              <i class="bi bi-phone">
                <b-icon-phone></b-icon-phone>
              </i>
              <h4>Call:</h4>
              <p>+1 919 386-8028</p>
            </div> -->

          <!-- <div class="col-lg-4">  
            <h2>
              <country-flag 
              country='ch' 
              size='big'/>
              {{ $t('contato.suica') }}
            </h2>
            <div class="address">              
              <h4>
                <i class="bi bi-geo-alt">
                  <b-icon-geo-alt></b-icon-geo-alt>
                </i>
                {{ $t('contato.endereco_titulo') }}
              </h4>
              <p>Samstagernstrasse 47, 8832 Wollerau (briefkasten)</p>
            </div>

            <div class="email">
              <h4>
                <i class="bi bi-envelope">
                  <b-icon-envelope></b-icon-envelope>
                </i>
                Email:
              </h4>
              <p><a href="mailto:fpalamone@rt-one.com">fpalamone@rt-one.com</a></p>
            </div> -->

            <!-- <div class="phone">
              <i class="bi bi-phone">
                <b-icon-phone></b-icon-phone>
              </i>
              <h4>Telefonenummer:</h4>
              <p>+1 919 386-8028</p>
            </div> -->

            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe> -->
        <!--  </div>-->
        <!-- </div> -->

        <!-- <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
          <form @submit="checkForm" id="contactForm" class="php-email-form">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{ $t('contato.nome') }}</label>
                <input type="text" name="name" class="form-control" id="name" required  v-model="nome">
              </div>
              <div class="form-group col-md-6">
                <label for="name">{{ $t('contato.email') }}</label>
                <input type="email" class="form-control" name="email" id="email" required v-model="email">
              </div>
            </div>
            <div class="form-group">
              <label for="name">{{ $t('contato.telefone') }}</label>
              <input type="tel" class="form-control" name="telefone" id="telefone" v-model="telefone">
            </div>
            <div class="form-group">
              <label for="name">{{ $t('contato.mensagem') }}</label>
              <textarea class="form-control" name="message" rows="10" required v-model="mensagem"></textarea>
            </div>
            <p v-if="ok">
              <b>{{ $t('contato.enviada') }}</b>
            </p>
            <p v-if="errors.length > 0">
              <b>{{ $t('contato.preencha') }}</b>
              <ul class="fa-ul">
                <li v-for="error in errors"  v-bind:key="error">{{ error }}</li>                    
              </ul>
            </p>
            <div class="text-center"><button type="submit">{{ $t('contato.enviar') }}</button></div>
          </form>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
  import CountryFlag from 'vue-country-flag';

  export default {
    name: "ContatoComponent",
    components: {
      CountryFlag
    },
    data: function () {
      return {
        errors: [],
        ok: false,
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
      }
    },
    methods: {
      checkForm: function (e) {
        this.ok = false;
        e.preventDefault();

        this.errors = [];

        if (this.nome === '') 
          this.errors.push('Nome completo');
        
        if (this.email === '') 
          this.errors.push('Email');
        else if (!this.validEmail(this.email)) 
          this.errors.push('Email inválido.');

        if (this.mensagem === '') 
          this.errors.push('Mensagem');

        if (this.errors.length > 0) 
          return;
        
        let msg_body = JSON.stringify(
          {
            app: 'RT1',
            nome: this.nome,
            email: this.email,
            telefone: this.telefone,
            mensagem: this.mensagem
          }
        );

        console.log(msg_body);

        fetch('https://apiteste.cobrealuguel.com.br/empresa/mensagem', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: msg_body
        }).then(res => {
          console.log('then');
          console.log(res);
          if (res.status === 200)
            this.ok = true;
          else
            this.errors.push('erro ao enviar mensagem. Contate pelo email contato@rt1.com.br');
        }).catch(error => {
          console.log('catch');
          if (error.response) {
            console.log(error.response);
          }

          this.errors.push('erro ao enviar mensagem. Contate pelo email contato@rt1.com.br');
        });
      },
      validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }      
    }
  };
</script>

<style scoped>
  .contact .info {
    border-top: 3px solid #006283;
    border-bottom: 3px solid #006283;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  }
  .contact .info i {
    font-size: 20px;
    color: #006283;
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #37517e;
  }
  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
  }
  .contact .info .email p {
    padding-top: 5px;
  }
  .contact .info .social-links {
    padding-left: 60px;
  }
  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }
  .contact .info .social-links a:hover {
    background: #006283;
    color: #fff;
  }
  .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #006283;
    color: #fff;
  }
  .contact .php-email-form {
    width: 100%;
    border-top: 3px solid #006283;
    border-bottom: 3px solid #006283;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  .contact .php-email-form .form-group {
    margin-bottom: 20px;
  }
  .contact .php-email-form label {
    padding-bottom: 8px;
  }
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #006283;
  }
  .contact .php-email-form input {
    height: 44px;
  }
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  .contact .php-email-form button[type=submit] {
    background: #006283;
    border: 0;
    padding: 12px 34px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  .contact .php-email-form button[type=submit]:hover {
    background: #209dd8;
  }
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>