<template>
  <section id="cliens" class="cliens section-bg">
    <div class="container">
      <div class="row" data-aos="zoom-in">
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="../assets/img/clients/ciadasmassas-clientes.png" class="img-fluid" alt="Cia das Massas">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="../assets/img/clients/Kibon.jpg" class="img-fluid" alt="Kibon">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="../assets/img/clients/rcap.jpg" class="img-fluid" alt="Reliable Capital Fund (EUA)">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="../assets/img/clients/family.jpg" class="img-fluid" alt="Family Food">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="../assets/img/clients/szx.jpg" class="img-fluid" alt="SZX Contábil">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="../assets/img/clients/finetex.png" class="img-fluid" alt="Finetex Tecidos">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "ClientesComponent",
  };
</script>

<style scoped>
  .cliens {
    padding: 12px 0;
    text-align: center;
  }
  .cliens img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
  }
  .cliens img:hover {
    filter: none;
    transform: scale(1.1);
  }
  @media (max-width: 768px) {
    .cliens img {
      max-width: 40%;
    }
  }
</style>