<template>
  <div id="modal_video_div">
    <span id="close-modal-video" onclick="document.getElementById('modal_video').style.display='none'">&times;</span>
    <center>
      <video id="modal_video" controls>
        <source id="modal_video_source" src="" type="video/mp4">
        Seu navegador não suporta vídeo.
      </video>
    </center>
  </div>
</template>

<script>
  export default {
    name: "ModalvideoComponent",
  };
</script>

<style scoped>
  video {
    height: 50%;
    width: 50%;
    object-fit: contain;
    position: center;
  }

  /* The Modal (background) */
  #modal_video_div {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* 100% Full width */
    height: 100%; /* 100% Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }

  /* The Close Button */
  #close-modal-video {
    position: absolute;
    top: 10px;
    right: 30px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  #close-modal-video:hover,
  #close-modal-video:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
</style>