<template>
  <div class="col-lg-4 col-md-6 portfolio-item" :class="filter">
    <div class="portfolio-img">
    <p data-gallery="portfolioGallery">
        <img :src="image" class="img-fluid" alt="">
    </p>
    <div class="portfolio-info">
      <h4>{{ info }}</h4>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PortfoliocapaComponent",
    props: ['filter', 'info', 'image']
  };
</script>

<style scoped>
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  .portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
  }
  .portfolio .portfolio-item .portfolio-img img {
    transition: all 0.6s;
  }
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all 0.3s;
    background: rgba(55, 81, 126, 0.8);
    padding: 10px 15px;
  }
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
  }
  .portfolio .portfolio-item .portfolio-info p {
    color: #f9fcfe;
    font-size: 14px;
    margin-bottom: 0;
  }
  .portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
  }
  .portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #006283;
  }
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  .portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.15);
  }
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
  }
</style>