<template>
  <section id="dep" class="services section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>{{ $t('depoimento.titulo') }}</h2>        
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
          <div class="icon-box">
            <p>
              <i>
                "{{ $t('depoimento.servbon') }}"
                <b> - Juliano Peretti, CEO Servbon distribuidora Kibon</b>
              </i>
            </p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="icon-box">
            <p>
              <i>
                "{{ $t('depoimento.finetex') }}"
                <b> - Fernando Costa, Finetex</b>
              </i>
            </p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon-box">
            <p>
              <i>
                "{{ $t('depoimento.family') }}"
                <b> - Rafael Matheus, CFO Family Food</b>
              </i>
            </p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon-box">
            <p>
              <i>
                "{{ $t('depoimento.marinco') }}"
                <b> - Cleber Marin, Marinco Conservas</b>
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "DepoimentosComponent",
  };
</script>