<template>
  <div class="col-lg-4 col-md-6 portfolio-item" :class="filter">
    <div class="portfolio-img">
      <p data-gallery="portfolioGallery">
        <img :src="imageThumb" class="img-fluid img-modal" alt="">
        <img :src="imageFull" class="img-modal-full" style="display: none">
      </p>
      <div class="portfolio-info">
        <h4>{{ info }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PortfolioitemComponent",
    props: ['filter', 'info', 'imageThumb', 'imageFull'],
    mounted() {
      var modal = document.getElementById('modal_div');
      var header = document.getElementById("header");
      var goTop = document.getElementById("goTop");
      var modalImg = document.getElementById("modal_image");
      var modalImages = document.querySelectorAll(".img-modal");
      modalImages.forEach(function(el) {
        el.addEventListener('click', function() {
          header.style.display = "none";
          goTop.style.display = "none";
          modal.style.display = "block";
          var parentEl = this.parentElement;
          modalImg.src = parentEl.querySelector(".img-modal-full").src;
          modalImg.alt = this.alt;
        });
      });

      // Qdo clica no <span> (x) ou imagem, fecha o modal
      var closeClick = function() {
        header.style.display = "block";
        goTop.style.display = "block";
        modal.style.display = "none";
      }
      
      var span = document.getElementById("close-modal");
      modalImg.onclick = closeClick;
      span.onclick = closeClick;
    }
  };  
</script>

<style scoped>
  .img-modal {
    cursor: pointer;
  }
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  .portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
  }
  .portfolio .portfolio-item .portfolio-img img {
    transition: all 0.6s;
  }
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all 0.3s;
    background: rgba(55, 81, 126, 0.8);
    padding: 10px 15px;
  }
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
  }
  .portfolio .portfolio-item .portfolio-info p {
    color: #f9fcfe;
    font-size: 14px;
    margin-bottom: 0;
  }
  .portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
  }
  .portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #006283;
  }
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  .portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.15);
  }
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
  }

  .portfolio-details {
    padding-top: 40px;
  }
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #006283;
  }
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #006283;
  }
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(55, 81, 126, 0.08);
  }
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  .portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
  }
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
</style>