<template>
  <section id="services" class="services section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('servicos.titulo') }}</h2>
        <p>
          {{ $t('servicos.desc') }}
        </p>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bx bi-globe"><b-icon-globe></b-icon-globe></i></div>
            <h4><b>{{ $t('servicos.p_1t') }}</b></h4>
            <p>{{ $t('servicos.p_1') }}</p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="icon-box">
            <div class="icon"><i class="bx bi-headset"><b-icon-headset></b-icon-headset></i></div>
            <h4><b>{{ $t('servicos.p_2t') }}</b></h4>
            <p>{{ $t('servicos.p_2') }}</p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bx bi-gear"><b-icon-gear></b-icon-gear></i></div>
            <h4><b>{{ $t('servicos.p_3t') }}</b></h4>
            <p>{{ $t('servicos.p_3') }}</p>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
          <div class="icon-box">
            <div class="icon"><i class="bx bi-briefcase"><b-icon-briefcase></b-icon-briefcase></i></div>
            <h4><b>{{ $t('servicos.p_4t') }}</b></h4>
            <p>{{ $t('servicos.p_4') }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "ServicosComponent",
  };
</script>

<style scoped>
  .services .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;
  }
  .services .icon-box .icon {
    margin-bottom: 10px;
  }
  .services .icon-box .icon i {
    color: #006283;
    font-size: 36px;
    transition: 0.3s;
  }
  .services .icon-box h4 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
  }
  .services .icon-box h4 a {
    color: #37517e;
    transition: ease-in-out 0.3s;
  }
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .services .icon-box:hover {
    transform: translateY(-10px);
  }
  .services .icon-box:hover h4 a {
    color: #006283;
  }
</style>