<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
  /* @import 'vendor/aos/aos.css'; */
  /* @import 'vendor/bootstrap/css/bootstrap.min.css';
  @import 'vendor/bootstrap-icons/bootstrap-icons.css'; 
  @import 'css/style.css';*/
</style>

<script>
  export default {
    name: 'App'
  }
</script>