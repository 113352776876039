import Vue from 'vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import Home from './components/home.vue'
import {initScript} from "./js/scripts.js"
import PPRT1 from './components/PoliticaPrivacidade/pp_rt1.vue'
import PPeasyDespesa from './components/PoliticaPrivacidade/pp_easydespesa.vue'
import PPeasyVenda from './components/PoliticaPrivacidade/pp_easyvenda.vue'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/style.css'
import {messages} from './language.js'
import CountryFlag from 'vue-country-flag'

Vue.config.productionTip = false;
Vue.prototype.$baseUrl = 'http://localhost:8000/';
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.component('country-flag', CountryFlag);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {path: '/', name: 'home', component: Home},
    {path: '/pprt1', name: 'PPRT1', component: PPRT1},
    {path: '/ppeasydespesa', name: 'PPeasyDespesa', component: PPeasyDespesa},
    {path: '/ppeasyvenda', name: 'PPeasyVenda', component: PPeasyVenda},
    {path: "*", component: Home}
  ]
})

var userLocale =
    (navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language).slice(0, 2);
userLocale = ['pt', 'en', 'de'].includes(userLocale)
      ? userLocale
      : 'en';

const i18n = new VueI18n({
  lazy: true,
  locale: userLocale,
  messages
});

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

new Vue({
  router,
  render: h => h(App),
  i18n,
  mounted() {
    initScript();
    console.log(userLocale);
  }
}).$mount('#app')