export const messages = {
    'pt': {
        home: {
            titulo: 'Gestão Empresarial Inteligente'
        },
        menu: {
            home: 'home',
            sobre: 'sobre',
            servicos: 'serviços',
            portfolio: 'portfólio',
            plano: 'planos',
            time: 'nosso time',
            faq: 'FAQ',
            depoimentos: 'depoimentos',
            contato: 'contato',
            links: "Links úteis",
            privacidade: "política de privacidade"
        },
        capa: {
            economize: 'Economize tempo e dinheiro com nossos softwares de gestão',
            completo: 'ERP completo e separado em módulos para a gestão completa de sua empresa. BI, CRM, Workflow, +',
            apresentacao: 'assista a uma apresentação'
        },
        sobre: {
            principais: 'Sobre nossas soluções principais',
            erp: 'O RT1 ERP visa atender empresas de pequeno, médio e grande porte de diferentes ramos de atividade. Seus módulos são totalmente integrados, auxiliando o controle e organização de toda a empresa, podendo ser customizado de acordo com suas necessidades. Elimine as planilhas, controles paralelos e sistemas legados, mas se ainda precisar deles, nós integramos tudo.',
            erp_1: 'Módulos completos, integrados e customizáveis. Emissão de NFe e NFSe',
            erp_2: 'Integração com outros sistemas. Salve seu investimento',
            erp_3: 'Conhecimento de negócio, consultoria para solução dos problemas mais difíceis',
            erp_4: 'Treinamento, suporte por canais personalizados, suporte premium, alocação de recursos',
            erp_5: 'Consultoria para auxiliar sua empresa na organização da operação e obtenção de resultados',
            bi: 'O RT1 BI é a solução ideal para sua empresa extrair e analisar dados. Através de ferramentas simples e monitores automáticos e pró-ativos, sua empresa terá informações sintéticas e analíticas precisas para a tomada de decisão. Pode ser usado com o seu sistema atual ou em conjunto com o RT1 ERP.',
            bi_1: 'Análise de informações para tomada de decisão',
            bi_2: 'Monitoramento pró-ativo de indicadores importantes',
            bi_3: 'Dados sintéticos e analíticos de todos os sistemas e fontes de dados da empresa',
            saiba: 'Saiba mais',
        },
        gestao: {
            titulo: 'Gestão de forma eficiente e organizada',
            desc: 'Sistemas informatizados devem acompanhar as necessidades da empresa. Precisam ser aderentes aos processos e terem a possibilidade de customização, adequação contínua ao negócio e integração com outros sistemas.',
            p_1t: 'Módulos completos',
            p_1: 'Acompanha a legislação vigente para emissão de documentos fiscais. Conceitos aplicados para organização financeira, definição de preços, vendas, compras, produção, controle de estoque, CMV e logística. Apoio através de RH, ordens de serviço, CRM, tarefas internas, gestão por processos.',
            p_2t: 'Apoio a operação',
            p_2: 'Treinamento, suporte por canais personalizados, suporte premium, alocação de recursos. Ajudamos sua empresa na sua organização e obtenção de resultados.',
            p_3t: 'Customização e desenvolvimento personalizados',
            p_3: 'Para que sua gestão seja eficiente, o sistema utilizado deve possuir total aderência ao seu negócio. O RT1 ERP é um sistema vivo, em constante evolução e que permite customização, acompanhando as necessidades de mudança e crescimento da empresa.',
        },
        implantacao: {
            titulo: 'Implantação relâmpago, resultados comprovados',
            desc: 'O RT1 ERP pode ser utilizado em nuvem (cloud seguro) através de nossa estrutura ou diretamente em uma nuvem privada e exclusiva da empresa, conferindo segurança e confiabilidade ao processo. As implantações de sistemas de mercado são custosas e complicadas, e muitas vezes nem são entregues. Conheça nossa solução para uma implantação em tempo recorde com sistema completo e de máxima aderência ao seu negócio com desenvolvimento customizado.',
            p_1: 'IMPLANTAÇÃO RT1 - ENTREGA RÁPIDA, ALTA ADERÊNCIA, CUSTO JUSTO E RÁPIDO RETORNO',
            p_2: 'CONCORRENTE GLOBAL - ALTO CUSTO, ENTREGA DEMORADA',
            p_3: 'CONCORRENTE NACIONAL - ALTO CUSTO, ENTREGA DEMORADA',
            p_4: 'CONCORRENTE REGIONAL - CUSTO MÉDIO, BAIXA ADERÊNCIA ',
        },
        servicos: {
            titulo: "SERVIÇOS",
            desc: "Ajudamos sua empresa a se organizar, crescer e obter resultados. Oferecemos suporte básico e premium em nossas soluções, integramos e reaproveitamos seus sistemas atuais e garantimos a contínua melhoria de sua equipe através de acompanhamento e consultoria.",
            p_1t: "ERP BI CRM +",
            p_1: "Completos, integrados, customizáveis e de baixa curva de aprendizado para a equipe",
            p_2t: "Suporte",
            p_2: "Básico ou premium, apoio a operação, treinamento e capacitação para toda a equipe",
            p_3t: "Desenvolvimento",
            p_3: "Analistas especialistas compreendem e customizam o sistema de acordo com a necessidade da empresa para aderência máxima ao seu negócio",
            p_4t: "Fiscal",
            p_4: "Consultoria fiscal para emissão de documentos, definição de produtos, classificação e adequação de impostos com planejamento tributário",
        },
        portfolio: {
            titulo: "PORTFÓLIO",
            desc: "O RT1 ERP é dividido em módulos integrados permitindo que sua empresa comece por uma implantação mais simples e cresça de acordo com a necessidade. Conheça abaixo um pouco de nossas soluções para os problemas mais difíceis do mundo empresarial.",
            assista: "Assista uma apresentação sobre o RT1 ERP",
            assista2: "Assista uma apresentação",
            financeiro: "Financeiro",
            financeiro_desc: "administração financeira completa e organizada",
            despesas: "Despesas",
            despesas_desc: "aprovação de despesas por funcionário, departamento e projeto.",
            vendas: "Vendas",
            vendas_desc: "vender mais com administração e segurança de crescimento",
            forcadevendas: "Força de Vendas",
            forcadevendas_desc: "Permite ao vendedor registrar pedidos pelo celular e os integra no ERP para faturamento rápido",
            pdv: "PDV",
            pdv_desc: "ponto de venda integrado com financeiro, estoque e vendas",
            crm: "CRM",
            crm_desc: "histórico de relacionamento com seus clientes. Últimos pedidos, parcelas, crédito",
            producao: "Produção",
            producao_desc: "produção de produtos com etapas, turnos, apuração de custos de máquinas e recursos",
            compras: "Compras",
            compras_desc: "requisição, cotação e compra com melhores condições apresentadas pelo sistema",
            estoque: "Estoque",
            estoque_desc: "múltiplos estoques, controle físico, reservado e disponível, CMV",
            logistica: "Logística",
            logistica_desc: "entregas totais ou parciais, pagamento contra entrega ou faturado. Entregas pelo celular",
            cobranca: "Cobrança",
            cobranca_desc: "histórico de cobrança, negociações e acordos. Parcelamento, recebimento total e parcial, fundo perdido",
            rh: "RH",
            rh_desc: "administração das ações de seus colaboradores, pagamentos e vales automatizados",
            documentos: "Documentos",
            documentos_desc: "gestão de biblioteca de arquivos físicos e digitais, disponíveis para todo o sistema com controle de acesso",
            sac: "SAC",
            sac_desc: "histórico de atendimento, emissão de protocolo, controle de retorno (feedback)",
            tarefas: "Tarefas",
            tarefas_desc: "modelos de tarefas para gestão de atividades, projetos, gestão de equipes",
            bpm: "BPM",
            bpm_desc: "controle de processos interligando todas as áreas da empresa. Gestão pró-ativa, padronização de execução de atividades",
            awa: "AWA",
            awa_desc: "automação de processamento de invoices para o mercado americano e europeu",
            os: "Ordens de Serviço",
            os_desc: "ordens de serviço pelo celular com captura de imagens, check-list personalizados e controle de tempo e execução",
            projetos: "Projetos",
            projetos_desc: "gestão de projetos com custos orçados, previstos e realizados",
            juridico: "Jurídico",
            juridico_desc: "gestão de processos, documentos, garantia de prazos, controle e andamento",
            clipping: "Clipping",
            clipping_desc: "registro de transcrições de programas de rádio, TV e internet. Controle de arquivos gravados. Triagens automáticas de palavras chave",
            imoveis: "Imóveis",
            imoveis_desc: "gestão de bens, contratos, recebimento e negociação",
            academico: "Acadêmico",
            academico_desc: "cursos, grade horária, conteúdo, notas, financeiro",
            bi: "Business Intelligence (BI)",
            bi_desc: "Extraction and analysis of information through reports, analysis grids, cubes, and maps.",
            monitor: "Monitor",
            monitor_desc: "monitoramento pró-ativo da operação com envio automático de emails e alertas",
            dashboard: "Dashboard",
            dashboard_desc: "indicadores, gráficos e informações eficientes para a tomada de decisão",
            calendario: "Calendário",
            calendario_desc: "todos os seus compromissos em um calendário centralizado. Vencimento de contas a pagar, contas a receber, reposição de estoque, CRM, workflow",
            auditor: "Auditor",
            auditor_desc: "registro de todas as operações efetuadas com data, hora, operação efetuada e responsável",
            integrador: "Integrador",
            integrador_desc: "ferramentas de integração para agregar seus softwares e dados",
            seguranca: "Segurança",
            seguranca_desc: "com controle de direitos de acesso, cada colaborador ou grupo acessa apenas as informações de sua competência",
        },
        acao: {
            titulo: "Mude a gestão de sua empresa",
            desc: "Vamos compreender suas necessidades e realizar a mudança de sua gestão, para melhor. Fale conosco.",
            agora: "Comece seu projeto agora",
        },
        time: {
            titulo: "NOSSO TIME",
            desc: "Nosso relacionamento é pessoal. Todos os nossos projetos entregam um time dedicado ao seu sucesso com contato profissional direto. Nossos líderes de equipe:",
            paulo_bio: "Líder de projetos de tecnologia em grandes empresas e há mais de 20 anos empreendendo com a RT1. Sólidos conhecimentos de negócio em áreas diversas, especialista em tecnologias e padrões de desenvolvimento organizado.",
            fernando_bio: "Consultor para as operações internacionais com o foco nos EUA e Europa. 25 anos de experiência liderando empresas de tecnologia e finanças na adoção de leis, metodologias e automatização de processos. Atuou em empresas como IBM, Google, VMware, Citrix, Cisco Systems, JSR e PDF Solutions. Graduado em Engenharia Naval pela USP e MBA em estratégia e finanças pela Universidade da Carolina do Norte.",
            poli_bio: "Formado em negócios bancários com MBA pela FGV, com sólida experiência na área comercial e 25 anos de atuação no mercado financeiro, focado no entendimento das necessidades de negócio e da entrega de melhores soluções e processos.",
            andre_bio: "Formado na área de tecnologia, MBA em Gerência de Projetos pela FGV, com mais de 25 anos de experiência em análise, desenvolvimento e implantação de sistemas de TI nas mais diversas áreas do comércio e indústria.",
            jose_bio: "Com vasta experiência em implementação, treinamento e suporte, lidera a equipe de auxílio à operação dos nossos clientes garantindo eficiência, redução de custos e melhoria operacional.",
            luis_bio: "Consultor para as principais necessidades tributárias no âmbito fiscal, no auxilio do gerenciamento das aplicações e regras de cálculo. Especialista em planejamento tributário e SPED (bloco K)."
        },
        plano: {
            titulo: "PLANOS",
            desc: "Nossos planos são flexíveis e comportam empresas de todos os tamanhos",
            nuvem: "Sistema em nuvem da RT1, sem instalação",
            implantacao: "Rápida implantação",
            usuarios: "Máximo de 10 usuários",
            suporte: "Suporte básico ou premium",
            integracao: "Integração entre sistemas",
            customizacao: "Customização e desenvolvimento personalizado",
            consultoria: "Consultoria de gestão",
            comecar: "Consultoria de gestão",
        },
        faq: {
            titulo: "PERGUNTAS FREQUENTES",
            p_1t: "Quanto tempo para ter o sistema pronto para uso na minha empresa?",
            p_1: "Depende de quantos módulos pretende implantar de início e se pretende importar dados antigos. Por exemplo, uma implantação em nossa estrutura de nuvem dos módulos financeiro, vendas, compras e estoque leva cinco dias úteis.",
            p_2t: "Posso começar com uma implantação pequena e aumentar aos poucos?",
            p_2: "Acreditamos que este é o melhor caminho. Sugerimos uma implantação pequena, gradual e contínua. Com isso podemos treinar e aculturar sua equipe e melhorar os processos cobertos pelos módulos sem atrapalhar o dia-a-dia da empresa. Se sua empresa não tem sistema, este processo irá introduzir a nova cultura, mas se já tem, permitirá que a curva de aprendizado seja segura, garantindo os resultados da mudança.",
            p_3t: "Posso reutilizar os dados que tenho em outro sistema ou em planilhas?",
            p_3: "Sim, e como parte da implantação, faremos um estudo dos seus dados atuais e um processo de migração destes dados para o RT1 ERP. Se os seus dados de origem estiverem acessíveis e íntegros, temos como reaproveitá-los na sua nova implantação.",
            p_4t: "Tenho sistema ou planilhas que me atendem bem hoje. Posso continuar utilizando e ainda integrar com o RT1 ERP?",
            p_4: "Pode sim. Se o seus sistemas atuais possuírem acesso, podemos ler e gravar infomações nestes sistemas, além de reaproveitar e suas planilhas e alimentá-las de volta, preservando a operação que já funciona hoje.",
            p_5t: "Com o RT1 ERP posso emitir notas de produtos e serviços?",
            p_5: "O sistema está preparado para emissão de NFe e NFSe. A emissão pode ser feita em lote com a configuração de impostos facilitada e de forma automática (opcional). Serviços de importação, DIFAL entre estados e demais exigências fiscais estão cobertas pelo RT1 ERP.",
            p_6t: "Quais tecnologias o RT1 ERP utiliza? Preciso pagar licença de banco de dados além do sistema?",
            p_6: "O sistema é executado diretamente no seu navegador, sem necessidade de instalação. Alguns módulos também funcionam no seu celular, permitindo agilidade e integração direta com o sistema central. Quanto ao banco de dados, utilizamos um banco relacional de grande capacidade e sem custo de licença por ser livre (open source). Possuímos clientes com mais de uma década de dados, sem perda de velocidade.",
        },
        depoimento: {
            titulo: "DEPOIMENTOS",
            servbon: "Nossa experiência com a RT1 sempre foi muito positiva. Os sistemas são bem desenvolvidos e abrangentes e os especialistas compreendem perfeitamente as necessidades da empresa e sempre nos entregam as melhores soluções para todas as nossas necessidades",
            finetex: "Somos clientes da RT1 já há alguns anos. O sistema oferecido por eles é completo, preciso e de uso muito amigável, mesmo para usuários refratários ao mundo digital. O suporte da RT1 é ágil, eficiente e super atencioso. Todas as vezes que solicitamos alguma customização, o atendimento foi imediato, o valor cobrado super justo e o resultado final sempre superou o solicitado. Costumo dizer que a RT1 têm o foco na solução. Mas não na solução deles e sim na solução para o cliente. Têm sido um prazer trabalhar com a RT1 e equipe",
            family: "A RT1 é parceira da Family Foods desde o início das operações. Todo o pacote de soluções nos atende com um sistema estável e com alta disponibilidade. O suporte, quando necessário, é dado por pessoas com alto conhecimento das soluções e as opções de relatório de análise são praticamente infinitas. Estamos bastante satisfeitos com a solução RT1",
            marinco: "O sistema é simples e direto, mas quando precisamos de customização, somos atendidos prontamente. Aposentei minhas planilhas e controles paralelos e faço tudo no RT1. Sempre que meu negócio cresce, o sistema acompanha",
        },
        contato: {
            titulo: "CONTATO",
            desc: "Pronto para iniciar seu projeto conosco? Mande-nos uma mensagem que entraremos em contato para tirarmos todas as suas dúvidas.",
            nome: "Nom completo",
            email: "E-Mail",
            telefone: "Telefone (opcional)",
            mensagem: "Mensagem",
            enviar: "Enviar",
            brasil: 'Brasil',
            eua: 'EUA',
            suica: 'Suíça',
            endereco_titulo: 'Endereço:'
        }
    },
    'en': {
        home: {
            titulo: 'Intelligent Business Management'
        },
        menu: {
            home: 'home',
            sobre: 'about',
            servicos: 'services',
            portfolio: 'portfolio',
            plano: 'plans',
            time: 'team',
            faq: 'FAQ',
            depoimentos: 'testimonials',
            contato: 'contact',
            links: "Useful links",
            privacidade: "privacy policy"
        },
        capa: {
            economize: 'Save time and money with our management software',
            completo: 'Complete ERP divided into modules for comprehensive company management. BI, CRM, Workflow, +',
            apresentacao: 'watch a presentation'
        },
        sobre: {
            principais: 'About our main solutions',
            erp: "RT1's Enterprise Resource Planning (ERP) solution is intricately designed to serve businesses of all scales across diverse industries. Offering fully interwoven modules with seamless integration, the aim is to facilitate comprehensive control and meticulous organization of the entire framework. These modules are fully customizable, tailored precisely to harmonize with your exacting needs. Eliminate cumbersome spreadsheets, redundant parallel controls, and antiquated legacy systems, as our ERP system brings new life to data integration. While at the same time maintaining what works and what is proven, seamlessly integrating them into the fabric of our solution. Our offering encompasses an array of features that exemplify its functionality and superiority:",
            erp_1: 'Complete, integrated, and customizable modules. Order processing.',
            erp_2: 'Seamless integration with external systems, a measure that safeguards your existing investments.',
            erp_3: 'Business knowledge and consulting for solving the most difficult problems.',
            erp_4: 'Training, custom channel support, premium support, resource allocation',
            erp_5: 'Consulting to assist your company in organizing operations and achieving results.',
            bi: 'The RT1 BI is the ideal solution for your company to extract and analyze data. Through simple tools and automatic, proactive monitors, your company will have accurate synthetic and analytical information for decision-making. It can be used with your current system or in conjunction with RT1 ERP.',
            bi_1: 'Analysis of information for decision-making.',
            bi_2: 'Proactive monitoring of key indicators.',
            bi_3: 'Synthetic and analytical data from all company systems and data sources.',
            saiba: 'Learn more',
        },
        gestao: {
            titulo: 'Efficient and organized management',
            desc: "Computerized systems must align with the company's needs. They need to be compliant with processes and have the potential for customization, ongoing adaptation to the business, and integration with other systems.",
            p_1t: 'Complete modules',
            p_1: 'Complies with current legislation for the issuance of fiscal documents. Applied concepts for financial organization, price definition, sales, purchases, production, inventory control, COGS, and logistics. Support through HR, work orders, CRM, internal tasks, process management.',
            p_2t: 'Operation support',
            p_2: 'Training, support through customized channels, premium support, resource allocation. We assist your company in its organization and achievement of results.',
            p_3t: 'Customization and personalized development',
            p_3: 'In order for your management to be efficient, the system used must have complete alignment with your business. The RT1 ERP is a dynamic system, constantly evolving and allowing customization, adapting to the changing needs and growth of the company.',
        },
        implantacao: {
            titulo: 'Rapid deployment, proven results',
            desc: "The RT1 ERP can be used in the cloud (secure cloud) through our infrastructure or directly in a company's private and exclusive cloud, providing security and reliability to the process. Market system implementations are costly and complicated, and often they are not even delivered. Discover our solution for a lightning-fast implementation with a complete system that maximally aligns with your business through customized development.",
            p_1: 'RT1 IMPLEMENTATION - FAST DELIVERY, HIGH ALIGNMENT, FAIR COST, AND QUICK RETURN',
            p_2: 'GLOBAL COMPETITOR - HIGH COST, DELIVERY TAKES TIME',
            p_3: 'NATIONAL COMPETITOR - HIGH COST, DELIVERY TAKES TIME',
            p_4: 'REGIONAL COMPETITOR - AVERAGE COST, LOW ALIGNMENT',
        },
        servicos: {
            titulo: "SERVICES",
            desc: "We assist your company in organizing, growing, and achieving results. We offer both basic and premium support for our solutions, integrate and leverage your current systems, and ensure continuous improvement of your team through monitoring and consultancy.",
            p_1t: "ERP BI CRM +",
            p_1: "Comprehensive, integrated, customizable, and with a shallow learning curve for the team.",
            p_2t: "Support",
            p_2: "Basic or premium, operational assistance, training, and skill-building for the entire team.",
            p_3t: "Development",
            p_3: "Expert analysts understand and customize the system according to the company's needs for maximum alignment with your business.",
            p_4t: "Taxation",
            p_4: "Tax consultancy for document issuance, product definition, classification, and tax adaptation with tax planning.",
        },
        portfolio: {
            titulo: "PORTFOLIO",
            desc: "The RT1 ERP is divided into integrated modules, allowing your company to start with a simpler implementation and grow according to need. Below, discover a bit about our solutions for the most challenging issues in the business world.",
            assista: "Watch a presentation about RT1 ERP",
            assista2: "Watch a presentation",
            financeiro: "Financial",
            financeiro_desc: "Complete and organized financial management",
            despesas: "Expenses",
            despesas_desc: "Approval of expenses by employee, department, and project.",
            vendas: "Sales",
            vendas_desc: "Selling more with growth-oriented management and security.",
            forcadevendas: "Seller App",
            forcadevendas_desc: "Allows the seller to place orders via mobile and integrates them into the ERP for quick invoicing.",
            pdv: "POS (Point of Sale)",
            pdv_desc: "Point of Sale integrated with finance, inventory, and sales.",
            crm: "CRM",
            crm_desc: "Customer relationship history. Recent orders, installments, credit.",
            producao: "Manufacturing",
            producao_desc: "Production of products with stages, shifts, cost calculation of machines and resources.",
            compras: "Purchases",
            compras_desc: "Request, quotation, and purchase with the best conditions provided by the system.",
            estoque: "Inventory",
            estoque_desc: "Multiple Stocks, Physical Control, Reserved and Available, CMV (Cost of Merchandise Sold)",
            logistica: "Logistics",
            logistica_desc: "Total or partial deliveries, payment upon delivery or invoiced. Deliveries through mobile phone.",
            cobranca: "Billing",
            cobranca_desc: "Billing history, negotiations, and agreements. Installment payment, full and partial receipt, non-repayable fund.",
            rh: "Human Resources",
            rh_desc: "Administration of employee stocks, automated payments, and vouchers",
            documentos: "Documents",
            documentos_desc: "Management of a library of physical and digital files, available throughout the system with access control.",
            sac: "Customer Support",
            sac_desc: "Service history, protocol issuance, return control to customer (feedback)",
            tarefas: "Tasks",
            tarefas_desc: "Task management models for activity, project, and team management.",
            bpm: "BPM",
            bpm_desc: "Process control interconnecting all areas of the company. Proactive management, standardization of activity execution.",
            awa: "AWA",
            awa_desc: "Invoice processing automation for the American and European markets.",
            os: "Work Orders",
            os_desc: "Mobile work orders with image capture, customized checklists, and time and execution control.",
            projetos: "Projects",
            projetos_desc: "Project management with budgeted, forecasted, and actual costs.",
            juridico: "Legal",
            juridico_desc: "Process management, document management, deadline assurance, control, and progress.",
            clipping: "Clipping",
            clipping_desc: "Recording of transcriptions from radio, TV, and internet programs. Control of recorded files. Automatic keyword screening.",
            imoveis: "Real Estate",
            imoveis_desc: "Asset management, contracts, receivables, and negotiation.",
            academico: "Academic",
            academico_desc: "School Administration of Courses, Timetable, Content, Grades, Financial",
            bi: "Business Intelligence (BI)",
            monitor: "Monitor",
            monitor_desc: "Proactive operation monitoring with automatic email notifications and alerts.",
            dashboard: "Dashboard",
            dashboard_desc: "Efficient indicators, charts, and information for decision-making.",
            calendario: "Calendar",
            calendario_desc: "All your appointments in a centralized calendar. Due bills, accounts receivable, restocking, CRM, workflow.",
            auditor: "Auditor",
            auditor_desc: "Recording of all operations carried out with date, time, performed operation, and responsible party.",
            integrador: "Integrator",
            integrador_desc: "Integration tools to aggregate your software and data.",
            seguranca: "Security",
            seguranca_desc: "With access rights control, each employee or group only accesses information within their competence.",
        },
        acao: {
            titulo: "Change Your Company's Management",
            desc: "We will understand your needs and bring about an improvement in your management. Get in touch with us.",
            agora: "Start your project now",
        },
        time: {
            titulo: "OUR TEAM",
            desc: "Our relationship is personal. All our projects deliver a dedicated team for your success with direct professional contact. Our team leaders:",
            paulo_bio: "Technology project leader in major companies and an entrepreneur with RT1 for over 20 years. Solid business knowledge in various fields, a specialist in technologies and organized development standards.",
            fernando_bio: "Consultant for international operations with a focus on the USA and Europe. 25 years of experience leading technology and finance companies in adopting laws, methodologies, and process automation. He has worked at companies like IBM, Google, VMware, Citrix, Cisco Systems, JSR, and PDF Solutions. Graduated in Naval Engineering from USP and holds an MBA in Strategy and Finance from the University of North Carolina.",
            poli_bio: "Degree in banking business with an MBA from FGV, with solid experience in the commercial area and 25 years of activity in the financial market, focused on understanding business needs and delivering better solutions and processes.",
            andre_bio: "Graduated in the technology field, MBA in Project Management from FGV, with over 25 years of experience in analysis, development, and implementation of IT systems in various areas of commerce and industry.",
            jose_bio: "With vast experience in implementation, training, and support, he leads the customer support team, ensuring efficiency, cost reduction, and operational improvement for our clients.",
            luis_bio: "Consultant for major tax needs in the fiscal scope, aiding in the management of applications and calculation rules. Specialist in tax planning."
        },
        plano: {
            titulo: "PLANS",
            desc: "Our plans are flexible and suitable for companies of all sizes.",
            nuvem: "RT1 cloud system, no installation",
            implantacao: "Quick deployment",
            usuarios: "Up to 10 users",
            suporte: "Basic or premium support",
            integracao: "Integration between systems",
            customizacao: "Customization and personalized development",
            consultoria: "Management consultancy",
            comecar: "Start now",
        },
        faq: {
            titulo: "FREQUENTLY ASKED QUESTIONS",
            p_1t: "How long does it take to have the system ready for use in my company?",
            p_1: "It depends on how many modules you intend to deploy initially and whether you plan to import old data. For example, deploying the financial, sales, purchases, and inventory modules in our cloud structure takes five business days.",
            p_2t: "Can I start with a small deployment and gradually expand?",
            p_2: "We believe this is the best approach. We suggest a small, gradual, and continuous deployment. This allows us to train and familiarize your team, improving the processes covered by the modules without disrupting your company's daily operations. If your company doesn't have a system, this process will introduce the new culture. If you already have one, it will ensure a safe learning curve, guaranteeing the change's results.",
            p_3t: "Can I reuse the data I have in another system or spreadsheets?",
            p_3: "Yes, as part of the deployment process, we will study your current data and migrate it to the RT1 ERP. If your source data is accessible and intact, we can reuse it in your new deployment.",
            p_4t: "I have a system or spreadsheets that serve me well today. Can I continue using them and still integrate with RT1 ERP?",
            p_4: "Yes, you can. If your current systems have access, we can read and write information in these systems. We can also reuse your spreadsheets and feed them back, preserving the operation that already works today.",
            p_5t: "Can I generate product and service invoices with RT1 ERP?",
            p_5: "The system is prepared for generating invoices that can be done in batches with simplified tax configuration. Inter-state tax differences, and other fiscal requirements are covered by RT1 ERP.",
            p_6t: "What technologies does RT1 ERP use? Do I need to pay a database license fee in addition to the system?",
            p_6: "The system runs directly in your web browser, with no need for installation. Some modules also work on your mobile device, enabling agility and direct integration with the central system. As for the database, we use a high-capacity relational database that is license-free as it's open source. We have clients with over a decade of data without a loss of speed.",
        },
        depoimento: {
            titulo: "TESTIMONIALS",
            servbon: "Our experience with RT1 has always been very positive. The systems are well-developed and comprehensive, and the experts fully understand the company's needs, consistently delivering the best solutions for all our requirements.",
            finetex: "We have been clients of RT1 for several years now. The system they offer is comprehensive, accurate, and very user-friendly, even for users resistant to the digital world. RT1's support is fast, efficient, and extremely attentive. Every time we requested customization, the response was immediate, the cost was more than fair, and the end result always exceeded our expectations. I often say that RT1 focuses on the solution – not their solution, but the solution for the customer. It has been a pleasure working with RT1 and their team.",
            family: "RT1 has been a partner of Family Foods since the beginning of operations. The entire package of solutions meets us with a stable system and high availability. Support, when needed, comes from people with in-depth knowledge of the solutions, and the report analysis options are practically endless. We are very satisfied with the RT1 solution.",
            marinco: "The system is simple and straightforward, but when we need customization, we are promptly assisted. I've retired my spreadsheets and parallel controls and do everything in RT1. Whenever my business grows, the system keeps up.",
        },
        contato: {
            titulo: "CONTACT",
            desc: "Ready to start your project with us? Send us a message, and we will get in touch to address all your questions.",
            nome: "Complete name",
            email: "E-Mail",
            telefone: "Phone (optional)",
            mensagem: "Message",
            enviada: "Your message has been sent. Your message has been sent. We will be in touch shortly.",
            preencha: "Please, fill out:",
            enviar: "Send",
            brasil: 'Brazil',
            eua: 'USA',
            suica: 'Switzerland',
            endereco_titulo: 'Address:'
        }
    },
    'de': {
        home: {
            titulo: 'Intelligente Unternehmensführung'
        },
        menu: {
            home: 'heim',
            sobre: 'über',
            servicos: 'dienstleistungen',
            portfolio: 'portfolie',
            plano: 'pläne',
            time: 'team',
            faq: 'HGF',
            depoimentos: 'zeugnisse',
            contato: 'kontakt',
            links: "Nützliche Links",
            privacidade: "datenschutzrichtlinie"
        },
        capa: {
            economize: 'Sparen Sie Zeit und Geld mit unseren Managementsoftware-Lösungen.',
            completo: 'Vollständige ERP-Lösung, aufgeteilt in Module, für die umfassende Unternehmensführung. BI, CRM, Workflow, +',
            apresentacao: 'lueg e Präsentation ah'
        },
        sobre: {
            principais: 'Über unsere Hauptlösungen',
            erp: 'Das RT1 ERP zielt darauf ab, kleine, mittlere und große Unternehmen aus verschiedenen Branchen zu bedienen. Seine Module sind vollständig integriert und unterstützen bei der Kontrolle und Organisation des gesamten Unternehmens. Es kann gemäß Ihren Bedürfnissen angepasst werden. Beseitigen Sie Tabellenkalkulationen, parallele Kontrollen und Altssysteme. Aber wenn Sie sie dennoch benötigen, integrieren wir alles.',
            erp_1: 'Vollständige, integrierte und anpassbare Module. Bestellabwicklung.',
            erp_2: 'Integration mit andere Systeme. Spar dis Investition.',
            erp_3: 'Businesswissen, Beratung zur Lösung der schwierigsten Probleme.',
            erp_4: 'Training, Unterstützung über individuelle Kanäle, Premium-Support, Ressourcenzuweisung',
            erp_5: 'Beratung zur Unterstützung Ihres Unternehmens bei der Organisation von Betriebsabläufen und der Erzielung von Ergebnissen.',
            bi: "De RT1 BI isch d'ideal Lösung für diini Firma, um Date usz'zieh und z'analysiere. Durich sempli Wärchzüg und automatischi, proaktiv Monitore wird diini Firma präzisi synthetischi und analytischi Informatione für d'Troffe vo Entscheidige ha. Er cha mit dim aktuelle System verwändet werde oder zäme mit RT1 ERP.",
            bi_1: 'Informationenanalyse für Entscheidungsfindung.',
            bi_2: 'Proaktive Überwachung wichtiger Kennzahlen.',
            bi_3: 'Synthetisierte und analytische Daten aus allen Unternehmenssystemen und Datenquellen.',
            saiba: 'Erfahre meh',
        },
        gestao: {
            titulo: 'Effizientes und organisiertes Management',
            desc: "Computerisierti System münd d'Bedürfniss vo dr Firme begleite. Si müesse sich a d'Prozäss aapasse lah und s'Spotential für Uschtaltig ha, stetigi Aapassig a's Gschäft und Integration mit andere System.",
            p_1t: 'Vollständige Module',
            p_1: 'Hält sich an die geltende Gesetzgebung für die Ausstellung von Steuerdokumenten. Angewandte Konzepte für die finanzielle Organisation, Preisfestlegung, Verkauf, Einkauf, Produktion, Lagerbestandskontrolle, Gesamtkosten der verkauften Waren (CMV) und Logistik. Unterstützung durch HR, Arbeitsaufträge, CRM, interne Aufgaben, Prozessmanagement.',
            p_2t: 'Betriebliche Unterstützung',
            p_2: 'Training, Unterstützung durch maßgeschneiderte Kanäle, Premium-Support, Ressourcenzuweisung. Wir unterstützen Ihr Unternehmen bei der Organisation und Erzielung von Ergebnissen.',
            p_3t: 'Individuell Aapassig und persönlichi Entwicklig',
            p_3: 'Damit Ihr Management effizient ist, muss das verwendete System vollständig auf Ihr Geschäft abgestimmt sein. Das RT1 ERP ist ein dynamisches System, das sich ständig weiterentwickelt und Anpassungen ermöglicht, um den sich ändernden Bedürfnissen und dem Wachstum des Unternehmens gerecht zu werden.',
        },
        implantacao: {
            titulo: 'Blitzschnelle Implementierung, bewiesene Ergebnisse',
            desc: "Das RT1 ERP kann über unsere Infrastruktur in der sicheren Cloud oder direkt in einer privaten und exklusiven Cloud des Unternehmens verwendet werden, was Sicherheit und Zuverlässigkeit für den Prozess bietet. Markt Systemimplementierungen sind kostspielig und kompliziert und werden oft nicht einmal durchgeführt. Entdecken Sie unsere Lösung für eine blitzschnelle Implementierung mit einem vollständigen System, das sich maximal auf Ihr Geschäft ausrichtet, durch individuelle Entwicklung.",
            p_1: 'RT1 IMPLEMENTIERUNG - SCHNELLE LIEFERUNG, HOHE AUSRICHTUNG, ANGEMESSENE KOSTEN UND SCHNELLE RÜCKKEHR VON',
            p_2: 'GLOBALE KONKURRENZ - HOHE KOSTEN, LANGE LIEFERZEIT',
            p_3: 'NATIONALE KONKURRENZ - HOHE KOSTEN, LANGE LIEFERZEIT',
            p_4: 'REGIONALE KONKURRENZ - DURCHSCHNITTLICHE KOSTEN, GERINGE AUSRICHTUNG',
        },
        servicos: {
            titulo: "DIENSTLEISTUNGEN",
            desc: "Wir unterstützen Ihr Unternehmen bei der Organisation, dem Wachstum und der Erzielung von Ergebnissen. Wir bieten sowohl grundlegende als auch Premium-Unterstützung für unsere Lösungen an, integrieren und nutzen Ihre aktuellen Systeme und gewährleisten eine kontinuierliche Verbesserung Ihres Teams durch Überwachung und Beratung.",            
            p_1t: "ERP BI CRM +",
            p_1: "Umfassend, integriert, anpassbar und mit einer geringen Lernkurve für das Team.",
            p_2t: "Unterstützung",
            p_2: "Basis- oder Premiumunterstützung, operationale Unterstützung, Schulung und Kompetenzaufbau für das gesamte Team.",
            p_3t: "Entwicklung",
            p_3: "Expertinnen-Analystinnen verstehen und passen das System gemäß den Unternehmensanforderungen an, um eine maximale Ausrichtung auf Ihr Geschäft zu gewährleisten.",
            p_4t: "Besteuerung",
            p_4: "Steuerberatung für die Ausstellung von Dokumenten, Definition von Produkten, Klassifizierung und steuerliche Anpassung mit Steuerplanung.",
        },
        portfolio: {
            titulo: "PORTFOLIO",
            desc: "Das RT1 ERP ist in integrierte Module unterteilt, wodurch es Ihrem Unternehmen ermöglicht wird, mit einer einfacheren Implementierung zu beginnen und entsprechend den Anforderungen zu wachsen. Nachfolgend erfahren Sie mehr über unsere Lösungen für die herausforderndsten Probleme in der Geschäftswelt.",
            assista: "Luäg dir e Präsentation iiber RT1 ERP aa",
            assista2: "Luäg dir e Präsentation",
            financeiro: "Finanzen",
            financeiro_desc: "Vollständige und organisierte Finanzverwaltung",
            despesas: "Ausgaben",
            despesas_desc: "Genehmigung von Ausgaben durch Mitarbeiter, Abteilung und Projekt.",
            vendas: "Vertrieb",
            vendas_desc: "Meh verchaufe mit Wachstumsorientierter Verwaltung und Sicherheit.",
            forcadevendas: "Vertrieb App",
            forcadevendas_desc: "Ermöglicht dem Verkäufer, Bestellungen über das Handy aufzugeben und integriert sie ins ERP-System für eine schnelle Rechnungsstellung.",
            pdv: "POS (Point of Sale)",
            pdv_desc: "Verkaufspunkt integriert mit Finanzen, Inventar und Verkauf.",
            crm: "CRM",
            crm_desc: "Kundenzusammenarbeitsverlauf. Letzte Bestellungen, Ratenzahlungen, Kredit.",
            producao: "Produktion",
            producao_desc: "Produktion von Produkten mit Phasen, Schichten, Kostenberechnung von Maschinen und Ressourcen.",
            compras: "Einkäufe",
            compras_desc: "Anfrage, Offerte und Einkauf mit den besten vom System bereitgestellten Konditionen.",
            estoque: "Inventar",
            estoque_desc: "Mehri Lager, Physischi Kontroll, Reserviert und Verfügbar, CMV (Koschte vo verkauftem Ware)",
            logistica: "Logistik",
            logistica_desc: "Vollständige oder teilweise Lieferungen, Zahlung bei Lieferung oder in Rechnung gestellt. Lieferungen per Handy.",
            cobranca: "Abrechnung",
            cobranca_desc: "Abrechnungshistorie, Verhandlungen und Vereinbarungen. Ratenzahlung, vollständiger und teilweiser Erhalt, nicht rückzahlbarer Fonds.",
            rh: "Personalabteilung",
            rh_desc: "Verwaltung von Mitarbeiteraktien, automatisierten Zahlungen und Gutscheinen",
            documentos: "Dokumente",
            documentos_desc: "Verwaltung einer Bibliothek von physischen und digitalen Dateien, verfügbar im gesamten System mit Zugriffskontrolle.",
            sac: "Kundendienst",
            sac_desc: "Dienstleistungsgeschichte, protokollaustellung, rückmeldekontrolle (Feedback)",
            tarefas: "Aufgaben",
            tarefas_desc: "Aufgabenmanagementmodelle für Aktivitäten-, Projekt- und Teammanagement.",
            bpm: "BPM (Geschäftsprozessmanagement)",
            bpm_desc: "Prozesskontrolle, die alle Bereiche des Unternehmens miteinander verbindet. Proaktives Management, Standardisierung der Durchführung von Aktivitäten.",
            awa: "AWA (Automatisierte Workflow-Anwendung)",
            awa_desc: "Automatisierung der Rechnungsverarbeitung für den amerikanischen und europäischen Markt.",
            os: "Arbeitsaufträge",
            os_desc: "Mobile Arbeitsaufträge mit Bildaufnahme, individuellen Checklisten und Zeit- und Ausführungskontrolle.",
            projetos: "Projekte",
            projetos_desc: "Projektmanagement mit budgetierten, prognostizierten und tatsächlichen Kosten.",
            juridico: "Juristisch",
            juridico_desc: "Prozessmanagement, Dokumentenmanagement, Terminsicherung, Kontrolle und Fortschritt.",
            clipping: "Ausschnitte",
            clipping_desc: "Ufzeichnig vu Transkriptione vo Radioprogramme, TV und Internet. Kontroll vu ufgenommene Dateie. Automatischi Schlüsselfwort-Auslesig.",
            imoveis: "Immobilien",
            imoveis_desc: "Vermögensverwaltung, Verträge, Forderungen und Verhandlung.",
            academico: "Akademisch",
            academico_desc: "Schulverwaltung von Kursen, Stundenplan, Inhalt, Noten, Finanzen",
            bi: "Business Intelligence (BI)",
            bi_desc: "Uszüg und Analyse vu Informatione durich Räport, Analysegridere, Cubes und Chatere.",
            monitor: "Monitor",
            monitor_desc: "Proaktive Betriebsüberwachung mit automatischem Versand von E-Mails und Warnmeldungen.",
            dashboard: "Dashboard",
            dashboard_desc: "Effiziente Indikatoren, Grafiken und Informationen zur Entscheidungsfindung.",
            calendario: "Kalender",
            calendario_desc: "All Ihre Termine in einem zentralisierten Kalender. Fällige Rechnungen, Forderungen, Warennachschub, CRM, Workflow.",
            auditor: "Prüfer",
            auditor_desc: "Erfassung aller durchgeführten Operationen mit Datum, Uhrzeit, durchgeführter Operation und verantwortlicher Person.",
            integrador: "Integrator",
            integrador_desc: "Integrationswerkzeuge, um Ihre Software und Daten zu bündeln.",
            seguranca: "Sicherheit",
            seguranca_desc: "Mit Zugriffsrechtskontrolle greift jeder Mitarbeiter oder jede Gruppe nur auf Informationen in ihrem Zuständigkeitsbereich zu.",
        },
        acao: {
            titulo: "Ändern Sie das Management Ihres Unternehmens",
            desc: "Wir werden Ihre Bedürfnisse verstehen und eine Verbesserung in Ihrem Management herbeiführen. Nehmen Sie Kontakt mit uns auf.",
            agora: "Starten Sie Ihr Projekt jetzt",
        },
        time: {
            titulo: "UNSER TEAM",
            desc: "Unsere Beziehung ist persönlich. Alle unsere Projekte stellen ein engagiertes Team für Ihren Erfolg mit direktem professionellem Kontakt zur Verfügung. Unsere Teamleiter:",
            paulo_bio: "Technologieprojektleiter in großen Unternehmen und Unternehmer bei RT1 seit über 20 Jahren. Solides Geschäftswissen in verschiedenen Bereichen, Spezialist für Technologien und organisierte Entwicklungstandards.",
            fernando_bio: "Berater für internationale Operationen mit Fokus auf den USA und Europa. 25 Jahre Erfahrung in der Leitung von Technologie- und Finanzunternehmen bei der Einführung von Gesetzen, Methodologien und Prozessautomatisierung. Er hat bei Unternehmen wie IBM, Google, VMware, Citrix, Cisco Systems, JSR und PDF Solutions gearbeitet. Abschluss in Schiffbauingenieurwesen von der USP und MBA in Strategie und Finanzen von der University of North Carolina.",
            poli_bio: "Abschluss in Bankgeschäften mit MBA von FGV, mit fundierter Erfahrung im kommerziellen Bereich und 25 Jahren Tätigkeit auf dem Finanzmarkt, konzentriert auf das Verständnis von Geschäftsbedürfnissen und die Bereitstellung besserer Lösungen und Prozesse.",
            andre_bio: "Absolvent im Technologiebereich, MBA in Projektmanagement von FGV, mit über 25 Jahren Erfahrung in der Analyse, Entwicklung und Implementierung von IT-Systemen in verschiedenen Bereichen von Handel und Industrie.",
            jose_bio: "Mit umfangreicher Erfahrung in Implementierung, Schulung und Support leitet er das Kundensupportteam und sorgt für Effizienz, Kostensenkung und betriebliche Verbesserung für unsere Kunden.",
            luis_bio: "Berater für wichtige steuerliche Anforderungen im fiskalischen Bereich, Unterstützung bei der Verwaltung von Anwendungen und Berechnungsregeln. Fachmann für Steuerplanung."
        },
        plano: {
            titulo: "PLÄNE",
            desc: "Unsere Pläne sind flexibel und für Unternehmen jeder Größe geeignet.",
            nuvem: "RT1-Cloud-System, keine Installation erforderlich",
            implantacao: "Schnelle Implementierung",
            usuarios: "Bis zu 10 Benutzer",
            suporte: "Grundlegender oder Premium-Support",
            integracao: "Integration zwischen Systemen",
            customizacao: "Anpassung und individuelle Entwicklung",
            consultoria: "Managementberatung",
            comecar: "Jetzt loslegen",
        },
        faq: {
            titulo: "HÄUFIG GESTELLTE FRAGEN",
            p_1t: "Wie lange dauert es, bis das System in meinem Unternehmen einsatzbereit ist?",
            p_1: "Das hängt davon ab, wie viele Module Sie zunächst implementieren möchten und ob Sie alte Daten importieren möchten. Zum Beispiel dauert die Implementierung der Finanz-, Verkaufs-, Einkaufs- und Lagermodule in unserer Cloud-Struktur fünf Werktage.",
            p_2t: "Kann ich mit einer kleinen Implementierung beginnen und schrittweise erweitern?",
            p_2: "Wir glauben, dass dies der beste Ansatz ist. Wir schlagen eine kleine, schrittweise und kontinuierliche Implementierung vor. Dadurch können wir Ihr Team schulen und vertraut machen, die durch die Module abgedeckten Prozesse verbessern, ohne den täglichen Betrieb Ihres Unternehmens zu stören. Wenn Ihr Unternehmen kein System hat, wird dieser Prozess die neue Kultur einführen. Wenn Sie bereits eines haben, wird er eine sichere Lernkurve gewährleisten und die Ergebnisse der Veränderung garantieren.",
            p_3t: "Kann ich die Daten, die ich in einem anderen System oder in Tabellen habe, wiederverwenden?",
            p_3: "Ja, im Rahmen des Implementierungsprozesses werden wir Ihre aktuellen Daten studieren und in das RT1 ERP migrieren. Wenn Ihre Quelldaten zugänglich und intakt sind, können wir sie in Ihrer neuen Implementierung wiederverwenden.",
            p_4t: "Ich habe ein System oder Tabellen, die mir heute gut dienen. Kann ich sie weiterhin verwenden und dennoch in das RT1 ERP integrieren?",
            p_4: "Ja, das ist möglich. Wenn Ihre aktuellen Systeme Zugriff haben, können wir Informationen in diesen Systemen lesen und schreiben. Wir können auch Ihre Tabellen wiederverwenden und sie zurückführen, um den Betrieb, der bereits heute funktioniert, zu erhalten.",
            p_5t: "Kann ich mit RT1 ERP Produkt- und Dienstleistungsrechnungen erstellen?",
            p_5: "Das System ist bereit zur Erstellung von Rechnungen, die in Stapeln mit vereinfachter Steuerkonfiguration durchgeführt werden können. Unterschiede in den zwischenstaatlichen Steuern und andere steuerliche Anforderungen werden von RT1 ERP abgedeckt.",
            p_6t: "Welche Technologien verwendet RT1 ERP? Muss ich zusätzlich zur Systemgebühr eine Datenbanklizenzgebühr zahlen?",
            p_6: "Das System läuft direkt in Ihrem Webbrowser, ohne Installation. Einige Module funktionieren auch auf Ihrem Mobilgerät und ermöglichen eine schnelle Integration mit dem Zentralsystem. Was die Datenbank betrifft, verwenden wir eine leistungsstarke relationale Datenbank, die lizenzfrei ist, da sie Open Source ist. Wir haben Kunden, die über ein Jahrzehnt lang Daten verwenden, ohne an Geschwindigkeit zu verlieren.",
        },
        depoimento: {
            titulo: "REFERENZEN",
            servbon: "Unsere Erfahrung mit RT1 war immer sehr positiv. Die Systeme sind gut entwickelt und umfassend, und die Experten verstehen die Bedürfnisse des Unternehmens vollständig und liefern stets die besten Lösungen für all unsere Anforderungen.",
            finetex: "Wir sind seit einigen Jahren Kunden von RT1. Das von ihnen angebotene System ist umfassend, präzise und sehr benutzerfreundlich, selbst für Nutzer, die dem digitalen Bereich skeptisch gegenüberstehen. Der Support von RT1 ist schnell, effizient und äußerst aufmerksam. Jedes Mal, wenn wir Anpassungen angefordert haben, war die Reaktion sofort, die Kosten mehr als fair und das Endergebnis hat immer unsere Erwartungen übertroffen. Ich sage oft, dass RT1 sich auf die Lösung konzentriert - nicht ihre Lösung, sondern die Lösung für den Kunden. Es ist eine Freude, mit RT1 und ihrem Team zusammenzuarbeiten.",
            family: "RT1 ist seit Beginn der Geschäftstätigkeit ein Partner von Family Foods. Das gesamte Lösungspaket erfüllt uns mit einem stabilen System und hoher Verfügbarkeit. Der Support, wenn erforderlich, kommt von Personen mit fundierten Kenntnissen der Lösungen, und die Analysemöglichkeiten für Berichte sind praktisch endlos. Wir sind mit der RT1-Lösung sehr zufrieden.",
            marinco: "Das System ist einfach und unkompliziert, aber wenn wir Anpassungen benötigen, werden wir umgehend unterstützt. Ich habe meine Tabellenkalkulationen und parallelen Kontrollen in den Ruhestand geschickt und erledige alles in RT1. Immer wenn mein Geschäft wächst, hält das System Schritt.",
        },
        contato: {
            titulo: "KONTAKT",
            desc: "Bereit, Ihr Projekt mit uns zu starten? Senden Sie uns eine Nachricht, und wir werden uns melden, um alle Ihre Fragen zu beantworten.",
            nome: "Vollständiger Name",
            email: "E-Mail",
            telefone: "Telefon (optional)",
            mensagem: "Nachricht",
            enviada: "Ihre Nachricht wurde gesendet. Wir werden uns bald bei Ihnen melden.",
            preencha: "Bitte ausfüllen:",
            enviar: "Nachricht senden",
            brasil: 'Brasilien',
            eua: 'USA',
            suica: 'Schwiz',
            endereco_titulo: 'Adresse:'
        }
    }
};