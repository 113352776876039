<template>
  <section id="skills" class="skills">
    <div class="container" data-aos="fade-up">

      <div class="row">
        <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
          <img src="../assets/img/skills.png" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
          <h3>{{ $t('implantacao.titulo') }}</h3>
          <p class="fst-italic">
            {{ $t('implantacao.desc') }}
          </p>

          <div class="skills-content">
            <div class="progress">
              <span class="skill">{{ $t('implantacao.p_1') }}<i class="val">100%</i></span>
              <!-- <div class="progress-bar">
                <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
            </div>

            <div class="progress">
              <span class="skill">{{ $t('implantacao.p_2') }}<i class="val">70%</i></span>
              <!-- <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
            </div>

            <div class="progress">
              <span class="skill">{{ $t('implantacao.p_3') }}<i class="val">60%</i></span>
              <!-- <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
            </div>

            <div class="progress">
              <span class="skill">{{ $t('implantacao.p_4') }}<i class="val">40%</i></span>
              <!-- <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "ImplantacaoComponent",
  };
</script>

<style scoped>
  .skills .content h3 {
    font-weight: 700;
    font-size: 32px;
    color: #37517e;
    font-family: "Poppins", sans-serif;
  }
  .skills .content ul {
    list-style: none;
    padding: 0;
  }
  .skills .content ul li {
    padding-bottom: 10px;
  }
  .skills .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #006283;
  }
  .skills .content p:last-child {
    margin-bottom: 0;
  }
  .skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }
  .skills .progress .skill {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #37517e;
  }
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
  }
  .skills .progress-bar-wrap {
    background: #e8edf5;
    height: 10px;
  }
  .skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: #4668a2;
  }
</style>