<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
          <h1>
            {{ $t('capa.economize') }}
          </h1>
          <h2>
            {{ $t('capa.completo') }}
          </h2>
          <!-- <div class="d-flex justify-content-center justify-content-lg-start">
            <a href="#about" class="btn-get-started scrollto">Mais informações</a>
          </div>           -->
          <PortfolioVideo
            v-if="$i18n.locale === 'pt'"
            id="video-capa"
            filter="filter-forca-venda" 
            :title="$t('capa.apresentacao')"
            :video="require('../assets/video/empresa-pt.mp4')">
          </PortfolioVideo>
          <PortfolioVideo
            v-if="$i18n.locale === 'en'"
            id="video-capa"
            filter="filter-forca-venda" 
            :title="$t('capa.apresentacao')"
            :video="require('../assets/video/empresa-en.mp4')">
          </PortfolioVideo>
          <PortfolioVideo
            v-if="$i18n.locale === 'de'"
            id="video-capa"
            filter="filter-forca-venda" 
            :title="$t('capa.apresentacao')"
            :video="require('../assets/video/empresa-de.mp4')">
          </PortfolioVideo>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <img src="../assets/img/hero-img.png" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import PortfolioVideo from './portfoliovideo.vue';

  export default {
    name: "CapaComponent",
    components: {
      PortfolioVideo
    }
  };
</script>