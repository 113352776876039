<template>
  <section id="pricing" class="pricing">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>{{ $t('plano.titulo') }}</h2>
        <p>{{ $t('plano.desc') }}</p>
      </div>

      <div class="row">

        <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="box">
            <h3>Starter</h3>
            <!-- <h4>
              <sup>$</sup>0
              <span>empresas de pequeno porte</span>
            </h4> -->
            <ul>
              <li><i class="bx bx-check"></i>{{ $t('plano.nuvem') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.implantacao') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.usuarios') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.suporte') }}</li>
              <li class="na"><i class="bx bx-x"></i> <span>{{ $t('plano.integracao') }}</span></li>
              <li class="na"><i class="bx bx-x"></i> <span>{{ $t('plano.customizacao') }}</span></li>
            </ul>
            <a href="#contact" class="buy-btn">{{ $t('plano.comecar') }}</a>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
          <div class="box featured">
            <h3>Professional</h3>
            <!-- <h4>
              <sup>$</sup>29
              <span>empresas de pequeno e médio porte</span>
            </h4> -->
            <ul>
              <li><i class="bx bx-check"></i>{{ $t('plano.nuvem') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.implantacao') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.usuarios') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.suporte') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.integracao') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.customizacao') }}</li>
              <li class="na"><i class="bx bx-x"></i><span>{{ $t('plano.consultoria') }}</span></li>
            </ul>
            <a href="#contact" class="buy-btn">{{ $t('plano.comecar') }}</a>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
          <div class="box">
            <h3>Enterprise</h3>
            <!-- <h4>
              <sup>$</sup>49
              <span>empresas de médio e grande porte</span>
            </h4> -->
            <ul>
              <li><i class="bx bx-check"></i>{{ $t('plano.nuvem') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.implantacao') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.usuarios') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.suporte') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.integracao') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.customizacao') }}</li>
              <li><i class="bx bx-check"></i>{{ $t('plano.consultoria') }}</li>
            </ul>
            <a href="#contact" class="buy-btn">{{ $t('plano.comecar') }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "PlanosComponent",
  };
</script>

<style scoped>
  .pricing .row {
    padding-top: 40px;
  }
  .pricing .box {
    padding: 60px 40px;
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    background: #fff;
    height: 100%;
    border-top: 4px solid #fff;
    border-radius: 5px;
  }
  .pricing h3 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 20px;
    color: #37517e;
  }
  .pricing h4 {
    font-size: 48px;
    color: #37517e;
    font-weight: 400;
    font-family: "Jost", sans-serif;
    margin-bottom: 25px;
  }
  .pricing h4 sup {
    font-size: 28px;
  }
  .pricing h4 span {
    color: #006283;
    font-size: 18px;
    display: block;
  }
  .pricing ul {
    padding: 20px 0;
    list-style: none;
    color: rgb(0, 0, 0);
    text-align: left;
    line-height: 20px;
  }
  .pricing ul li {
    padding: 10px 0 10px 30px;
    position: relative;
  }
  .pricing ul i {
    color: #28a745;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 6px;
  }
  .pricing ul .na {
    color: rgb(143, 143, 143);
  }
  .pricing ul .na i {
    color: rgb(143, 143, 143);
  }
  /* .pricing ul .na span {
    text-decoration: line-through;
  } */
  .pricing .buy-btn {
    display: inline-block;
    padding: 12px 35px;
    border-radius: 50px;
    color: #006283;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    transition: 0.3s;
    border: 1px solid #006283;
  }
  .pricing .buy-btn:hover {
    background: #006283;
    color: #fff;
  }
  .pricing .featured {
    border-top-color: #006283;
  }
  .pricing .featured .buy-btn {
    background: #006283;
    color: #fff;
  }
  .pricing .featured .buy-btn:hover {
    background: #23a3df;
  }
  @media (max-width: 992px) {
    .pricing .box {
      max-width: 60%;
      margin: 0 auto 30px auto;
    }
  }
  @media (max-width: 767px) {
    .pricing .box {
      max-width: 80%;
      margin: 0 auto 30px auto;
    }
  }
  @media (max-width: 420px) {
    .pricing .box {
      max-width: 100%;
      margin: 0 auto 30px auto;
    }
  }
</style>