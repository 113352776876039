<template>
  <section id="why-us" class="why-us section-bg">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

          <div class="content">
            <h3>{{ $t('gestao.titulo') }}</h3>
            <p>
              {{ $t('gestao.desc') }}
            </p>
          </div>

          <div class="accordion-list">
            <ul>
              <li>
                <p data-bs-target="#accordion-list-1">
                  <span>01</span> <b>{{ $t('gestao.p_1t') }}</b></p>
                <div id="accordion-list-1" data-bs-parent=".accordion-list">
                  <p>
                    {{ $t('gestao.p_1') }}
                  </p>
                </div>
              </li>

              <li>
                <p data-bs-target="#accordion-list-2">
                  <span>02</span> <b>{{ $t('gestao.p_2') }}</b></p>
                <div id="accordion-list-2" data-bs-parent=".accordion-list">
                  <p>
                    {{ $t('gestao.p_2') }}
                  </p>
                </div>
              </li>

              <li>
                <p data-bs-target="#accordion-list-3" class="collapsed">
                  <span>03</span> <b>{{ $t('gestao.p_3t') }}</b></p>
                <div id="accordion-list-3" data-bs-parent=".accordion-list">
                  <p>
                    {{ $t('gestao.p_3') }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" data-aos="zoom-in" data-aos-delay="150">
          <img src="../assets/img/why-us.png" class="img-fluid animated" alt="">          
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "GestaoComponent",
  };
</script>

<style scoped>
  .why-us .content {
    padding: 60px 100px 0 100px;
  }
  .why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
    color: #37517e;
  }
  .why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  .why-us .content p {
    font-size: 15px;
    color: #848484;
  }
  .why-us .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .why-us .accordion-list {
    padding: 0 100px 60px 100px;
  }
  .why-us .accordion-list ul {
    padding: 0;
    list-style: none;
  }
  .why-us .accordion-list li + li {
    margin-top: 15px;
  }
  .why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }
  .why-us .accordion-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    cursor: pointer;
  }
  .why-us .accordion-list span {
    color: #006283;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }
  .why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  .why-us .accordion-list .icon-show {
    display: none;
  }
  .why-us .accordion-list a.collapsed {
    color: #343a40;
  }
  .why-us .accordion-list a.collapsed:hover {
    color: #006283;
  }
  .why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
  }
  .why-us .accordion-list a.collapsed .icon-close {
    display: none;
  }
  @media (max-width: 1024px) {
    .why-us .content, .why-us .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 992px) {
    .why-us .img {
      min-height: 400px;
    }
    .why-us .content {
      padding-top: 30px;
    }
    .why-us .accordion-list {
      padding-bottom: 30px;
    }
  }
  @media (max-width: 575px) {
    .why-us .img {
      min-height: 200px;
    }
  }
</style>