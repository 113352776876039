<template>
  <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">
      <div class="row">
        <div class="col-lg-9 text-center text-lg-start">
          <h3>{{ $t('acao.titulo') }}</h3>
          <p>{{ $t('acao.desc') }}</p>
        </div>
        <div class="col-lg-3 cta-btn-container text-center">
          <a class="cta-btn align-middle" href="#contact">{{ $t('acao.agora') }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "AcaoComponent",
  };
</script>

<style scoped>
  .cta {
    background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../assets/img/cta-bg.jpg") fixed center center;
    background-size: cover;
    padding: 120px 0;
  }
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  .cta p {
    color: #fff;
  }
  .cta .cta-btn {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  .cta .cta-btn:hover {
    background: #006283;
    border: 2px solid #006283;
  }
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
</style>