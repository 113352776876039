<template>
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>{{ $t('sobre.principais') }}</h2>
      </div>

      <div class="row content">
        <div class="col-lg-6">
          <p>
            {{ $t('sobre.erp') }}
          </p>
          <ul>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.erp_1') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.erp_2') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.erp_3') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.erp_4') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.erp_5') }}</li>
          </ul>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            {{ $t('sobre.bi') }}
          </p>
          <ul>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.bi') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.bi') }}</li>
            <li><i class="ri-check-double-line"></i>{{ $t('sobre.bi') }}</li>
          </ul>
          <a href="#why-us" class="btn-learn-more">{{ $t('sobre.saiba') }}</a>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
  export default {
    name: "SobreComponent",
  };
</script>

<style scoped>
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  .about .content ul {
    list-style: square;
    padding: 20px;
  }
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  .about .content ul li + li {
    margin-top: 10px;
  }
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #006283;
    line-height: 1;
  }
  .about .content p:last-child {
    margin-bottom: 0;
  }
  .about .content .btn-learn-more {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.3s;
    line-height: 1;
    color: #006283;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #006283;
  }
  .about .content .btn-learn-more:hover {
    background: #006283;
    color: #fff;
    text-decoration: none;
  }
</style>