<template>
  <div>
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact">
              <img src="../assets/img/logort1.png" alt="" class="img-fluid">
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Links úteis</h4>
              <ul>
                <li><i class="bx bx-chevron-right"></i> <a href="#about">{{ $t('menu.sobre') }}</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#services">{{ $t('menu.servicos') }}</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#portfolio">{{ $t('menu.portfolio') }}</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#pricing">{{ $t('menu.plano') }}</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#team">{{ $t('menu.time') }}</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#faq">{{ $t('menu.faq') }}</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#dep">{{ $t('menu.depoimentos') }}</a></li>
                <li><i class="bx bx-chevron-right"></i>
                  <router-link :to="{ name: 'PPRT1' }">{{ $t('menu.privacidade') }}</router-link>
                </li>
              </ul>
            </div>

            <!-- <div class="col-lg-3 col-md-6 footer-links">
              <h4>Nossas redes sociais</h4>
              <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
              <div class="social-links mt-3">
                <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
              </div>
            </div>           -->
          </div>
        </div>
      </div>

      <div class="container footer-bottom clearfix">
        <div class="copyright">
          &copy; <strong><span>RT1 Software & Serviços</span></strong>. Todos os direitos reservados
        </div>
      </div>
    </footer>  

    <!-- <div id="preloader"></div> -->
    <div id="goTop">
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center">
        <b-icon icon="arrow-up" variant="dark"></b-icon>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FooterComponent",
  };
</script>

<style scoped>
  #footer {
    font-size: 14px;
    background: #37517e;
  }
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #f3f5fa;
    text-align: center;
    font-size: 15px;
    color: #444444;
  }
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #37517e;
  }
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
  }
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #006283;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-newsletter form input[type=submit]:hover {
    background: #209dd8;
  }
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    color: #006283;
  }
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    color: #5e5e5e;
  }
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #37517e;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #006283;
    font-size: 18px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #006283;
  }
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #006283;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .footer-top .social-links a:hover {
    background: #209dd8;
    color: #fff;
    text-decoration: none;
  }
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
  }
  #footer .copyright {
    float: left;
  }
  #footer .credits {
    float: right;
    font-size: 13px;
  }
  #footer .credits a {
    transition: 0.3s;
  }
  @media (max-width: 768px) {
    #footer .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    #footer .copyright, #footer .credits {
      text-align: center;
      float: none;
    }
    #footer .credits {
      padding-top: 4px;
    }
  }
</style>