<template>
  <div>
    <MenuComponent/>
    <CapaComponent/>
    <ModalComponent/>
    <ModalvideoComponent/>
    
    <main id="main">
      <ClientesComponent/>
      <SobreComponent/>
      <GestaoComponent/>
      <ImplantacaoComponent/>
      <ServicosComponent/>
      <PortifolioComponent/>
      <AcaoComponent/>
      <!-- <TimeComponent/> -->
      <PlanosComponent/>
      <FaqComponent/>
      <DepoimentosComponent/>
      <ContatoComponent/>
      <FooterComponent/>
    </main>
  </div>
</template>

<script>
  import MenuComponent from './menu.vue'
  import CapaComponent from './capa.vue'
  import ModalComponent from './modal.vue'
  import ModalvideoComponent from './modalvideo.vue'
  import ClientesComponent from './clientes.vue'
  import SobreComponent from './sobre.vue'
  import GestaoComponent from './gestao.vue'
  import ImplantacaoComponent from './implantacao.vue'
  import ServicosComponent from './servicos.vue'
  import PortifolioComponent from './portifolio.vue'
  import AcaoComponent from './acao.vue'
  // import TimeComponent from './time.vue'
  import PlanosComponent from './planos.vue'
  import FaqComponent from './faq.vue'
  import DepoimentosComponent from './depoimentos.vue'
  import ContatoComponent from './contato.vue'
  import FooterComponent from './footer.vue'

  export default {
    name: 'HomeComponent',
    components: {
      MenuComponent,
      CapaComponent,
      ModalComponent,
      ModalvideoComponent,
      ClientesComponent,
      SobreComponent,
      GestaoComponent,
      ImplantacaoComponent,
      ServicosComponent,
      PortifolioComponent,
      AcaoComponent,
      // TimeComponent,
      PlanosComponent,
      FaqComponent,
      DepoimentosComponent,
      ContatoComponent,
      FooterComponent,
    }
  }
</script>