<template>
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>{{ $t('portfolio.titulo') }}</h2>
        <p>{{ $t('portfolio.desc') }}</p>
      </div>

      <div class="d-flex justify-content-center">
        <PortfolioVideo
          v-if="$i18n.locale === 'pt'"
          :title="$t('portfolio.assista')"
          :video="require('../assets/video/erp-pt.mp4')">
        </PortfolioVideo>
        <PortfolioVideo
          v-if="$i18n.locale === 'en'"
          :title="$t('portfolio.assista')"
          :video="require('../assets/video/erp-en.mp4')">
        </PortfolioVideo>
        <PortfolioVideo
          v-if="$i18n.locale === 'de'"
          :title="$t('portfolio.assista')"
          :video="require('../assets/video/erp-de.mp4')">
        </PortfolioVideo>
      </div>
      
      <br/>

      <ul id="portfolio-flters" class="d-flex justify-content-center flex-wrap" data-aos="fade-up" data-aos-delay="100">
        <li id="portfolio-financeiro" data-filter=".filter-financeiro" class="filter-active">{{ $t('portfolio.financeiro') }}</li>
        <li data-filter=".filter-despesas">{{ $t('portfolio.despesas') }}</li>
        <li data-filter=".filter-vendas">{{ $t('portfolio.vendas') }}</li>
        <li data-filter=".filter-forca-venda">{{ $t('portfolio.forcadevendas') }}</li>
        <li data-filter=".filter-pdv">{{ $t('portfolio.pdv') }}</li>
        <li data-filter=".filter-crm">{{ $t('portfolio.crm') }}</li>
        <li data-filter=".filter-producao">{{ $t('portfolio.producao') }}</li>
        <li data-filter=".filter-compras">{{ $t('portfolio.compras') }}</li>
        <li data-filter=".filter-estoque">{{ $t('portfolio.estoque') }}</li>
        <li data-filter=".filter-logistica">{{ $t('portfolio.logistica') }}</li>
        <!-- <li data-filter=".filter-web">Logística Mobile</li> -->
        <li data-filter=".filter-cobranca">{{ $t('portfolio.cobranca') }}</li>
        <li data-filter=".filter-rh">{{ $t('portfolio.rh') }}</li>
        <li data-filter=".filter-documentos">{{ $t('portfolio.documentos') }}</li>
        <li data-filter=".filter-sac">{{ $t('portfolio.sac') }}</li>
        <li data-filter=".filter-tarefas">{{ $t('portfolio.tarefas') }}</li>
        <li data-filter=".filter-rpa">{{ $t('portfolio.bpm') }}</li>
        <li data-filter=".filter-awa">{{ $t('portfolio.awa') }}</li>
        <li data-filter=".filter-os">{{ $t('portfolio.os') }}</li>
        <!-- <li data-filter=".filter-web">O.S. Mobile</li> -->
        <li data-filter=".filter-projetos">{{ $t('portfolio.projetos') }}</li>
        <li data-filter=".filter-juridico">{{ $t('portfolio.juridico') }}</li>
        <!-- <li data-filter=".filter-casting">Casting</li> -->
        <li data-filter=".filter-clipping">{{ $t('portfolio.clipping') }}</li>
        <li data-filter=".filter-imoveis">{{ $t('portfolio.imoveis') }}</li>
        <li data-filter=".filter-academico">{{ $t('portfolio.academico') }}</li>
        <li data-filter=".filter-bi">{{ $t('portfolio.bi') }}</li>
        <li data-filter=".filter-monitor">{{ $t('portfolio.monitor') }}</li>
        <li data-filter=".filter-dashboard">{{ $t('portfolio.dashboard') }}</li>
        <li data-filter=".filter-calendario">{{ $t('portfolio.calendario') }}</li>
        <li data-filter=".filter-auditor">{{ $t('portfolio.auditor') }}</li>
        <li data-filter=".filter-integrador">{{ $t('portfolio.integrador') }}</li>
        <li data-filter=".filter-seguranca">{{ $t('portfolio.seguranca') }}</li>
      </ul>

      <div class="row portfolio-container d-flex justify-content-center" data-aos="fade-up" data-aos-delay="200">
        <div class="row portfolio-container container-fluid" data-aos="fade-up" data-aos-delay="200"></div>
      
        <!-- Financeiro -->        
        <PortfolioCapa
          filter="filter-financeiro" :info="$t('portfolio.financeiro_desc')"
          :image="require('../assets/img/portfolio/financeiro/capa.jpg')">
        </PortfolioCapa>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-financeiro" info="sistema de aprovação e segurança evita pagamentos indevidos"
          :imageThumb="require('../assets/img/portfolio/financeiro/ContasaPagar-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/financeiro/ContasaPagar.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-financeiro" info="situação de recebíveis em aberto, baixados, fundo perdido"
          :imageThumb="require('../assets/img/portfolio/financeiro/ContasaReceber-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/financeiro/ContasaReceber.jpg')">
        </PortfolioItem>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-financeiro" info="lançamentos históricos de caixas e bancos, controle de conciliação"
          :imageThumb="require('../assets/img/portfolio/financeiro/ConciliarContasCorrenteseCaixas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/financeiro/ConciliarContasCorrenteseCaixas.jpg')">
        </PortfolioItem>   

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-financeiro" info="encontre lançamentos rapidamente"
          :imageThumb="require('../assets/img/portfolio/financeiro/LocalizarTransacao-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/financeiro/LocalizarTransacao.jpg')">
        </PortfolioItem>
  
        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-financeiro" info="impede lançamentos passados em períodos conciliados e contabilizados"
          :imageThumb="require('../assets/img/portfolio/financeiro/Fechamento-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/financeiro/Fechamento.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-financeiro" info="previsto, realizado e gerado automaticamente a partir dos lançamentos da operação"
          :imageThumb="require('../assets/img/portfolio/financeiro/FluxodeCaixa-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/financeiro/FluxodeCaixa.jpg')">
        </PortfolioItem>

        <!-- Despesas -->
        <PortfolioCapa
          filter="filter-despesas"  :info="$t('portfolio.despesas_desc')"
          :image="require('../assets/img/portfolio/despesas/capa.jpg')">
        </PortfolioCapa>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-despesas" info="apontamento de despesas por projeto, com aprovação"
          :imageThumb="require('../assets/img/portfolio/despesas/Apontamentodedespesasthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/despesas/Apontamentodedespesas.jpg')">
        </PortfolioItem>

        <!-- Vendas -->
        <PortfolioCapa
          filter="filter-vendas"  :info="$t('portfolio.vendas_desc')"
          :image="require('../assets/img/portfolio/vendas/capa.jpg')">
        </PortfolioCapa>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-vendas" info="acompanhe seus resultados em tempo real"
          :imageThumb="require('../assets/img/portfolio/vendas/DashboardVendas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/vendas/DashboardVendas.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-vendas" info="pedidos de venda flexíveis por vendedor. Administrador determina condições do pedido"
          :imageThumb="require('../assets/img/portfolio/vendas/PedidosdeVenda-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/vendas/PedidosdeVenda.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-vendas" info="bonificações sem geração de recebíveis"
          :imageThumb="require('../assets/img/portfolio/vendas/BonificaçõesaClientes-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/vendas/BonificaçõesaClientes.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-vendas" info="NFe emitidas em lote, com formatos pré-configurados conferem agilidade e confiabilidade para a operação"
          :imageThumb="require('../assets/img/portfolio/vendas/GerarNFe-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/vendas/GerarNFe.jpg')">
        </PortfolioItem>

        <!-- Força de Vendas -->
        <PortfolioCapa
          filter="filter-forca-venda"  :info="$t('portfolio.forcadevendas_desc')"
          :image="require('../assets/img/portfolio/forcavendas/capa.jpg')">
        </PortfolioCapa>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-forca-venda" info="vendedor emite pedidos sem precisar de internet e sincroniza com a central quando quiser"
          :imageThumb="require('../assets/img/portfolio/forcavendas/pedidoscelular-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/forcavendas/pedidoscelular.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-forca-venda" info="agenda de visitas por vendedor"
          :imageThumb="require('../assets/img/portfolio/forcavendas/agendavisitas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/forcavendas/agendavisitas.jpg')">
        </PortfolioItem>

        <PortfolioVideo
          v-if="$i18n.locale === 'pt'"
          id="video-force-venda"
          filter="filter-forca-venda" 
          title=" Assista uma apresentação"
          :video="require('../assets/video/video_easyvenda.mp4')">
        </PortfolioVideo>

        <!-- PDV -->
        <PortfolioCapa
          filter="filter-pdv"  :info="$t('portfolio.pdv_desc')"
          :image="require('../assets/img/portfolio/pdv/capa.jpg')">
        </PortfolioCapa>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-pdv" info="pedidos de venda rápida"
          :imageThumb="require('../assets/img/portfolio/pdv/PedidodeVendanoPDV-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/pdv/PedidodeVendanoPDV.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-pdv" info="sincronização de lançamentos das filiais com a central"
          :imageThumb="require('../assets/img/portfolio/pdv/MovimentacaodoCaixa-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/pdv/MovimentacaodoCaixa.jpg')">
        </PortfolioItem>

        <!-- CRM -->
        <PortfolioCapa
          filter="filter-crm" :info="$t('portfolio.crm_desc')"
          :image="require('../assets/img/portfolio/crm/capa.jpg')">
        </PortfolioCapa>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-crm" info="Histórico de ocorrências"
          :imageThumb="require('../assets/img/portfolio/crm/MonitordeRelacionamentoCRM-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/crm/MonitordeRelacionamentoCRM.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-crm" info="Histórico de ocorrências"
          :imageThumb="require('../assets/img/portfolio/crm/MonitordeRelacionamentoCRM-Info-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/crm/MonitordeRelacionamentoCRM-Info.jpg')">
        </PortfolioItem>

        <!-- Produção -->
        <PortfolioCapa
          filter="filter-producao" :info="$t('portfolio.producao_desc')"
          :image="require('../assets/img/portfolio/producao/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-producao" info="produtção de produto específico, abaixo de estoque mínimo ou por pedidos"
          :imageThumb="require('../assets/img/portfolio/producao/GerarOrdemdeProducaothumb.jpg')"
          :imageFull="require('../assets/img/portfolio/producao/GerarOrdemdeProducao.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-producao" info="monitoramento completo de todas as etapas"
          :imageThumb="require('../assets/img/portfolio/producao/Monitordeproducaothumb.jpg')"
          :imageFull="require('../assets/img/portfolio/producao/Monitordeproducao.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-producao" info="check-list para controle de qualidade"
          :imageThumb="require('../assets/img/portfolio/producao/Controlequalidadeprodutothumb.jpg')"
          :imageFull="require('../assets/img/portfolio/producao/Controlequalidadeproduto.jpg')">
        </PortfolioItem>

        <!-- Compras -->
        <PortfolioCapa
          filter="filter-compras" :info="$t('portfolio.compras_desc')"
          :image="require('../assets/img/portfolio/compras/capa.jpg')">
        </PortfolioCapa>          

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-compras" info="pedidos de compra flexíveis por comprador. Administrador determina condições do pedido"
          :imageThumb="require('../assets/img/portfolio/compras/PedidosdeCompra-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/compras/PedidosdeCompra.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-compras" info="entregas totais e parciais. Pagamento após entrega ou faturado"
          :imageThumb="require('../assets/img/portfolio/compras/SituaçãodeEntregadosPedidosdeCompra-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/compras/SituaçãodeEntregadosPedidosdeCompra.jpg')">
        </PortfolioItem>
        
        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-compras" info="geração de pedido de compra automático de acordo com NFe do fornecedor"
          :imageThumb="require('../assets/img/portfolio/compras/ImportarNFedeEntrada-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/compras/ImportarNFedeEntrada.jpg')">
        </PortfolioItem>

        <!-- Estoque -->
        <PortfolioCapa
          filter="filter-estoque" :info="$t('portfolio.estoque_desc')"
          :image="require('../assets/img/portfolio/estoque/capa.jpg')">
        </PortfolioCapa>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-estoque" info="um ou mais locais físicos de estoque podem ser cadastrados. Estoques próprios ou de terceiros"
          :imageThumb="require('../assets/img/portfolio/estoque/RelacaodePrecosporFornecedor-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/estoque/RelacaodePrecosporFornecedor.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-estoque" info="entradas detalhadas por operação"
          :imageThumb="require('../assets/img/portfolio/estoque/EntradadeMateriais-Analitico-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/estoque/EntradadeMateriais-Analitico.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-estoque" info="saídas detalhadas por operação"
          :imageThumb="require('../assets/img/portfolio/estoque/SaidasdeMateriais-Analitico-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/estoque/SaidasdeMateriais-Analitico.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-estoque" info="entradas sintéticas"
          :imageThumb="require('../assets/img/portfolio/estoque/EntradadeMateriais-Sintetico-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/estoque/EntradadeMateriais-Sintetico.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-estoque" info="saídas sintéticas"
          :imageThumb="require('../assets/img/portfolio/estoque/SaidasdeMateriais-Sintetico-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/estoque/SaidasdeMateriais-Sintetico.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-estoque" info="todas as movimnentações do produto com data, hora, responsável e origem do lançamento"
          :imageThumb="require('../assets/img/portfolio/estoque/HistoricodeMovimentacaodeEstoque-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/estoque/HistoricodeMovimentacaodeEstoque.jpg')">
        </PortfolioItem>

        <!-- Logística -->
        <PortfolioCapa
          filter="filter-logistica" :info="$t('portfolio.logistica_desc')"
          :image="require('../assets/img/portfolio/logistica/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-logistica" info="situações de entrega personalizadas, controle total das quantidades entregues e pagamentos gerados"
          :imageThumb="require('../assets/img/portfolio/logistica/EntregaThumb.jpg')"
          :imageFull="require('../assets/img/portfolio/logistica/Entrega.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-logistica" info="organização da logística por setores de entrega"
          :imageThumb="require('../assets/img/portfolio/logistica/Setoresdeentrega-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/logistica/Setoresdeentrega.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-logistica" info="melhores rotas para entrega com cerca digital"
          :imageThumb="require('../assets/img/portfolio/logistica/MapadeEntrega-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/logistica/MapadeEntrega.jpg')">
        </PortfolioItem>

        <!-- Cobrança -->
        <PortfolioCapa
          filter="filter-cobranca" :info="$t('portfolio.cobranca_desc')"
          :image="require('../assets/img/portfolio/cobranca/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-cobranca" info="tipos de objeto de cobrança"
          :imageThumb="require('../assets/img/portfolio/cobranca/Tiposdeobjetosdecobrança-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/cobranca/Tiposdeobjetosdecobrança.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-cobranca" info="objetos de cobrança"
          :imageThumb="require('../assets/img/portfolio/cobranca/Objetosdecobrança-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/cobranca/Objetosdecobrança.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-cobranca" info="cadastro e acompnhamento de cobranças"
          :imageThumb="require('../assets/img/portfolio/cobranca/Cadastrodecobranças-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/cobranca/Cadastrodecobranças.jpg')">
        </PortfolioItem>

        <!-- Documentos -->
        <PortfolioCapa
          filter="filter-documentos" :info="$t('portfolio.documentos_desc')"
          :image="require('../assets/img/portfolio/documentos/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-documentos" info="Registro de documentos físicos e eletrônicos"
          :imageThumb="require('../assets/img/portfolio/documentos/Documentos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/Documentos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-documentos" info="Organização de documentos em pastas"
          :imageThumb="require('../assets/img/portfolio/documentos/Pastas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/Pastas.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-documentos" info="Controle de retirada e devolução de documentos com prazo e aviso automático"
          :imageThumb="require('../assets/img/portfolio/documentos/Retiradaedevolucaodedocumentos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/Retiradaedevolucaodedocumentos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-documentos" info="Permissão de acesso para funcionários e grupos"
          :imageThumb="require('../assets/img/portfolio/documentos/PermissoesdeAcessoaDocumentos-Doc-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/PermissoesdeAcessoaDocumentos-Doc.jpg')">
        </PortfolioItem>

        <!-- SAC -->
        <PortfolioCapa
          filter="filter-sac" :info="$t('portfolio.sac_desc')"
          :image="require('../assets/img/portfolio/sac/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-sac" info="Monitor de atendimento com emissão de protocolo"
          :imageThumb="require('../assets/img/portfolio/sac/Atendimento-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/sac/Atendimento.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-sac" info="Ocorrências pré-definidas permitem rápido atendimento e ações automatizadas"
          :imageThumb="require('../assets/img/portfolio/sac/OcorrenciasPre-definidas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/sac/OcorrenciasPre-definidas.jpg')">
        </PortfolioItem>

        <!-- Tarefas -->
        <PortfolioCapa
          filter="filter-tarefas" :info="$t('portfolio.tarefas_desc')"
          :image="require('../assets/img/portfolio/tarefas/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-tarefas" info="tudo o que deve ser feito pelo seu time na ordem correta de prioridade"
          :imageThumb="require('../assets/img/portfolio/tarefas/ListaTarefasThumb.jpg')"
          :imageFull="require('../assets/img/portfolio/tarefas/ListaTarefas.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-tarefas" info="modelos permitem criar tipos de tarefa para qualquer situação"
          :imageThumb="require('../assets/img/portfolio/tarefas/ModelosdeTarefasThumb.jpg')"
          :imageFull="require('../assets/img/portfolio/tarefas/ModelosdeTarefas.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-tarefas" info="respostas automatizadas podem ser configuradas para cada etapa da tarefa"
          :imageThumb="require('../assets/img/portfolio/tarefas/ModelodeMensagemthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/tarefas/ModelodeMensagem.jpg')">
        </PortfolioItem>

        <!-- BPM -->
        <PortfolioCapa
          filter="filter-rpa" :info="$t('portfolio.bpm_desc')"
          :image="require('../assets/img/portfolio/rpa/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rpa" info="definição dos processamentos operacionais"
          :imageThumb="require('../assets/img/portfolio/rpa/Procedimentos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rpa/Procedimentos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rpa" info="processos em andamento"
          :imageThumb="require('../assets/img/portfolio/rpa/Processos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rpa/Processos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rpa" info="monitor pró-ativo de atividades"
          :imageThumb="require('../assets/img/portfolio/rpa/MonitordeProcessos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rpa/MonitordeProcessos.jpg')">
        </PortfolioItem>      
        
        <PortfolioVideo
          v-if="$i18n.locale === 'pt'"
          id="video-bpm"
          filter="filter-rpa" s
          :title="$t('portfolio.assista2')"
          :video="require('../assets/video/bpa-pt.mp4')">
        </PortfolioVideo>
        <PortfolioVideo
          v-if="$i18n.locale === 'en'"
          id="video-bpm"
          filter="filter-rpa" s
          :title="$t('portfolio.assista2')"
          :video="require('../assets/video/bpa-en.mp4')">
        </PortfolioVideo>
        <PortfolioVideo
          v-if="$i18n.locale === 'de'"
          id="video-bpm"
          filter="filter-rpa" s
          :title="$t('portfolio.assista2')"
          :video="require('../assets/video/bpa-de.mp4')">
        </PortfolioVideo>

        <!-- AWA -->
        <PortfolioCapa
          filter="filter-awa" :info="$t('portfolio.awa_desc')"
          :image="require('../assets/img/portfolio/awa/capa.jpg')">
        </PortfolioCapa> 

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-awa" info="fila de análise e aprovação de invoices"
          :imageThumb="require('../assets/img/portfolio/awa/InvoiceWorkQueue-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/awa/InvoiceWorkQueue.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-awa" info="invoices são identificadas e classificadas automaticamente"
          :imageThumb="require('../assets/img/portfolio/awa/Process-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/awa/Process.jpg')">
        </PortfolioItem>

        <!-- OS -->
        <PortfolioCapa
          filter="filter-os" :info="$t('portfolio.os_desc')"
          :image="require('../assets/img/portfolio/os/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-os" info="Acompanhamento das OS por situação"
          :imageThumb="require('../assets/img/portfolio/os/OrdemdeServicothumb.jpg')"
          :imageFull="require('../assets/img/portfolio/os/OrdemdeServico.jpg')">
        </PortfolioItem>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-os" info="Ordens de serviço com atividades e check-lists configuráveis"
          :imageThumb="require('../assets/img/portfolio/os/RelacaodeOSthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/os/RelacaodeOS.jpg')">
        </PortfolioItem>        

        <!-- Projetos -->
        <PortfolioCapa
          filter="filter-projetos" :info="$t('portfolio.projetos_desc')"
          :image="require('../assets/img/portfolio/projetos/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-projetos" info="classificações"
          :imageThumb="require('../assets/img/portfolio/projetos/Classificacoesdeprojetosthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/projetos/Classificacoesdeprojetos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-projetos" info="gestores e responsáveis"
          :imageThumb="require('../assets/img/portfolio/projetos/GestoreseResponsaveisdeProjetosthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/projetos/GestoreseResponsaveisdeProjetos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-projetos" info="valores orçados, previstos e realizados"
          :imageThumb="require('../assets/img/portfolio/projetos/RegistroseAdministracaodeProjetosthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/projetos/RegistroseAdministracaodeProjetos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-projetos" info="apuração de resultados por contas gerenciais (centros de custo)"
          :imageThumb="require('../assets/img/portfolio/projetos/AdministracaodeProjetosOrcamentosthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/projetos/AdministracaodeProjetosOrcamentos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-projetos" info="rateio de valores por projetos e contas (centros de custo)"
          :imageThumb="require('../assets/img/portfolio/projetos/Rateio-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/projetos/Rateio.jpg')">
        </PortfolioItem>

        <!-- Jurídico -->
        <PortfolioCapa
          filter="filter-juridico" :info="$t('portfolio.juridico_desc')"
          :image="require('../assets/img/portfolio/juridico/capa.jpg')">
        </PortfolioCapa>        

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-juridico" info="processos em andamento"
          :imageThumb="require('../assets/img/portfolio/rpa/Processos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rpa/Processos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-juridico" info="monitor pró-ativo de atividades"
          :imageThumb="require('../assets/img/portfolio/rpa/MonitordeProcessos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rpa/MonitordeProcessos.jpg')">
        </PortfolioItem>  

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-juridico" info="Registro de documentos físicos e eletrônicos"
          :imageThumb="require('../assets/img/portfolio/documentos/Documentos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/Documentos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-juridico" info="Organização de documentos em pastas"
          :imageThumb="require('../assets/img/portfolio/documentos/Pastas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/Pastas.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-juridico" info="Controle de retirada e devolução de documentos com prazo e aviso automático"
          :imageThumb="require('../assets/img/portfolio/documentos/Retiradaedevolucaodedocumentos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/Retiradaedevolucaodedocumentos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-juridico" info="Permissão de acesso para funcionários e grupos"
          :imageThumb="require('../assets/img/portfolio/documentos/PermissoesdeAcessoaDocumentos-Doc-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/documentos/PermissoesdeAcessoaDocumentos-Doc.jpg')">
        </PortfolioItem>

        <!-- Clipping -->
        <PortfolioCapa
          filter="filter-clipping" :info="$t('portfolio.clipping_desc')"
          :image="require('../assets/img/portfolio/clipping/capa.jpg')">
        </PortfolioCapa>            

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-clipping" info="textos transcritos de TV, rádio e demais mídias"
          :imageThumb="require('../assets/img/portfolio/clipping/registroTranscricoes-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/clipping/registroTranscricoes.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-clipping" info="triagem automática das transcrições para identificação de novos clientes ou contabilidade de exibição"
          :imageThumb="require('../assets/img/portfolio/clipping/triagemTranscricoes-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/clipping/triagemTranscricoes.jpg')">
        </PortfolioItem>

        <!-- Imóveis -->
        <PortfolioCapa
          filter="filter-imoveis" :info="$t('portfolio.imoveis_desc')"
          :image="require('../assets/img/portfolio/imoveis/capa.jpg')">
        </PortfolioCapa>            

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-imoveis" info="registro completo de imóveis e terrenos. Documentos, fotos"
          :imageThumb="require('../assets/img/portfolio/imoveis/ImoveiseTerrenos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/imoveis/ImoveiseTerrenos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-imoveis" info="valores, reajustes, abonos, obrigações"
          :imageThumb="require('../assets/img/portfolio/imoveis/ContratosdeLocacao-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/imoveis/ContratosdeLocacao.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-imoveis" info="prestação de contas detalhada para o locatário"
          :imageThumb="require('../assets/img/portfolio/imoveis/DemonstrativodeAluguel-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/imoveis/DemonstrativodeAluguel.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-imoveis" info="controle de reembolso por benfeitoria ou acordos"
          :imageThumb="require('../assets/img/portfolio/imoveis/ReembolsodeDespesas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/imoveis/ReembolsodeDespesas.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-imoveis" info="por região, perfil de locação, potencial, etc"
          :imageThumb="require('../assets/img/portfolio/imoveis/GruposdeImoveis-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/imoveis/GruposdeImoveis.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-imoveis" info="apartamentos, casas, terrenos, galpões, etc"
          :imageThumb="require('../assets/img/portfolio/imoveis/TiposdeImoveis-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/imoveis/TiposdeImoveis.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-imoveis" info="registro de índices para cálculo de reajustes"
          :imageThumb="require('../assets/img/portfolio/imoveis/IndicesFinanceiros-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/imoveis/IndicesFinanceiros.jpg')">
        </PortfolioItem>

        <!-- Acadêmico -->
        <PortfolioCapa
          filter="filter-academico" :info="$t('portfolio.academico_desc')"
          :image="require('../assets/img/portfolio/academico/capa.jpg')">
        </PortfolioCapa>            

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-academico" info="criação de cursos de diferentes perfis"
          :imageThumb="require('../assets/img/portfolio/academico/CadastroCursos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/academico/CadastroCursos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-academico" info="registro completo com financeiro integrado"
          :imageThumb="require('../assets/img/portfolio/academico/MatriculaAluno-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/academico/MatriculaAluno.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-academico" info="registro de frequência"
          :imageThumb="require('../assets/img/portfolio/academico/frequenciaAula-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/academico/frequenciaAula.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-academico" info="notas e faltas"
          :imageThumb="require('../assets/img/portfolio/academico/notasfaltas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/academico/notasfaltas.jpg')">
        </PortfolioItem>

        <!-- BI -->
        <PortfolioCapa
          filter="filter-bi" :info="$t('portfolio.bi_desc')"
          :image="require('../assets/img/portfolio/bi/capa.jpg')">
        </PortfolioCapa>           

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-bi" info="consulte informações no RT1 ou nos seus demais sistemas"
          :imageThumb="require('../assets/img/portfolio/bi/CadastrodeConsultas-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/bi/CadastrodeConsultas.jpg')">
        </PortfolioItem>
        
        <!-- RH -->
        <PortfolioCapa
          filter="filter-rh" :info="$t('portfolio.rh_desc')"
          :image="require('../assets/img/portfolio/rh/capa.jpg')">
        </PortfolioCapa>              

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rh" info="registro completo de funcionários, pagamento de vale a salário"
          :imageThumb="require('../assets/img/portfolio/rh/Funcionarios-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rh/Funcionarios.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rh" info="histórico de registro de salários"
          :imageThumb="require('../assets/img/portfolio/rh/FuncionariosSalario-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rh/FuncionariosSalario.jpg')">
        </PortfolioItem>
        
        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rh" info="histórico completo com documentos"
          :imageThumb="require('../assets/img/portfolio/rh/PremioLimitesdeFaltaseAtestados-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rh/PremioLimitesdeFaltaseAtestados.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rh" info="períodos flexíveis"
          :imageThumb="require('../assets/img/portfolio/rh/TurnosdeTrabalho-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rh/TurnosdeTrabalho.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rh" info="organização de funcionários por turmas"
          :imageThumb="require('../assets/img/portfolio/rh/TurmasdeTrabalho-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rh/TurmasdeTrabalho.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-rh" info="registro de ponto"
          :imageThumb="require('../assets/img/portfolio/rh/ponto-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/rh/ponto.jpg')">
        </PortfolioItem>

        <!-- Monitor -->
        <PortfolioCapa
          filter="filter-monitor" :info="$t('portfolio.monitor_desc')"
          :image="require('../assets/img/portfolio/monitor/monitor.jpg')">
        </PortfolioCapa>                      

        <!-- Dashboard -->
        <PortfolioCapa
          filter="filter-dashboard" :info="$t('portfolio.dashboard_desc')"
          :image="require('../assets/img/portfolio/dashboard/capa.jpg')">
        </PortfolioCapa>         

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-dashboard" info="diversos formatos para a análise precisa de informações"
          :imageThumb="require('../assets/img/portfolio/dashboard/Dashboardthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/dashboard/Dashboard.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-dashboard" info="análise do resultado de vendas"
          :imageThumb="require('../assets/img/portfolio/dashboard/DashboardVendasthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/dashboard/DashboardVendas.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-dashboard" info="por cidade, tipo de produto, etc"
          :imageThumb="require('../assets/img/portfolio/dashboard/DashboardVendasCidadesthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/dashboard/DashboardVendasCidades.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-dashboard" info="consulte rapidamente indicadores importes ao negócio"
          :imageThumb="require('../assets/img/portfolio/dashboard/DashboardVendasPedidosthumb.jpg')"
          :imageFull="require('../assets/img/portfolio/dashboard/DashboardVendasPedidos.jpg')">
        </PortfolioItem>

        <!-- Calendário -->
        <PortfolioCapa
          filter="filter-calendario" :info="$t('portfolio.calendario_desc')"
          :image="require('../assets/img/portfolio/calendario/capa.jpg')">
        </PortfolioCapa>           

        <!-- Auditor -->
        <PortfolioCapa
          filter="filter-auditor" :info="$t('portfolio.auditor_desc')"
          :image="require('../assets/img/portfolio/auditor/capa.jpg')">
        </PortfolioCapa>           

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-auditor" info="registro completo das operações por data, hora e responsável, valores incluídos, alterados ou apagados"
          :imageThumb="require('../assets/img/portfolio/auditor/auditor-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/auditor/auditor.jpg')">
        </PortfolioItem>

        <!-- Integrador -->
        <PortfolioCapa
          filter="filter-integrador" :info="$t('portfolio.integrador_desc')"
          :image="require('../assets/img/portfolio/integrador/integrador.jpg')">
        </PortfolioCapa>           

        <!-- Segurança -->
        <PortfolioCapa
          filter="filter-seguranca" :info="$t('portfolio.seguranca_desc')"
          :image="require('../assets/img/portfolio/seguranca/capa.jpg')">
        </PortfolioCapa>              

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-seguranca" info="registro de usuários para acesso e auditoria da operação"
          :imageThumb="require('../assets/img/portfolio/seguranca/Usuarios-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/seguranca/Usuarios.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-seguranca" info="grupos de usuários permites da definição de acesso por alçada"
          :imageThumb="require('../assets/img/portfolio/seguranca/Grupos-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/seguranca/Grupos.jpg')">
        </PortfolioItem>

        <PortfolioItem
          v-if="$i18n.locale === 'pt'"
          filter="filter-seguranca" info="direitos de acesso em telas, campos e dados para usuários e grupos"
          :imageThumb="require('../assets/img/portfolio/seguranca/DireitosdeAcesso-thumb.jpg')"
          :imageFull="require('../assets/img/portfolio/seguranca/DireitosdeAcesso.jpg')">
        </PortfolioItem> 
      </div>
    </div>
  </section>
</template>

<script>
  import PortfoliocapaComponent from './portfoliocapa.vue';
  import PortfolioitemComponent from './portfolioitem.vue';
  import PortfoliovideoComponent from './portfoliovideo.vue';
  
  export default {
    name: "PortifolioComponent",
    components: {
      PortfolioCapa: PortfoliocapaComponent,
      PortfolioItem: PortfolioitemComponent,
      PortfolioVideo: PortfoliovideoComponent
    }
  };
</script>

<style scoped>
  .portfolio #portfolio-flters {
    list-style: none;
    margin-bottom: 20px;
  }
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    margin: 10px 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    color: #444444;
    transition: all 0.3s;
    padding: 8px 20px;
    border-radius: 50px;
    font-family: "Poppins", sans-serif;
  }
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    background: #006283;
    color: #fff;
  }
</style>