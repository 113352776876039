<template>
  <header id="header" class="fixed-top ">
    <div class="container d-flex align-items-center">
      <a href="#hero" class="logo me-auto"><img src="../assets/img/logo.png" alt="" class="img-fluid"></a>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">{{ $t('menu.home') }}</a></li>
          <li><a class="nav-link scrollto" href="#about">{{ $t('menu.sobre') }}</a></li>
          <li><a class="nav-link scrollto" href="#services">{{ $t('menu.servicos') }}</a></li>
          <li><a class="nav-link scrollto" href="#portfolio">{{ $t('menu.portfolio') }}</a></li>
          <li><a class="nav-link scrollto" href="#pricing">{{ $t('menu.plano') }}</a></li>
          <!-- <li><a class="nav-link scrollto" href="#team">{{ $t('menu.time') }}</a></li> -->
          <li><a class="nav-link scrollto" href="#faq">{{ $t('menu.faq') }}</a></li>
          <li><a class="nav-link scrollto" href="#dep">{{ $t('menu.depoimentos') }}</a></li>
          <li><a class="nav-link scrollto" href="#contact">{{ $t('menu.contato') }}</a></li>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <li>&nbsp;<country-flag 
            v-on:click.native="$i18n.locale = 'pt'" 
            country='br' 
            size='normal' 
            class="custom-flag"/></li>
          <li>&nbsp;<country-flag
            v-on:click.native="$i18n.locale = 'en'"
            country='us' 
            size='normal' 
            class="custom-flag"/></li>
          <li>&nbsp;<country-flag v-on:click.native="$i18n.locale = 'de'"
            country='ch' 
            size='normal' 
            class="custom-flag"/></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle">
          <li class="no-bullets">&nbsp;<country-flag 
            v-on:click.native="$i18n.locale = 'pt'" 
            country='br' 
            size='normal' 
            class="custom-flag"/></li>
          <li class="no-bullets">&nbsp;<country-flag
            v-on:click.native="$i18n.locale = 'en'"
            country='us' 
            size='normal' 
            class="custom-flag"/></li>
          <li class="no-bullets">&nbsp;<country-flag v-on:click.native="$i18n.locale = 'de'"
            country='ch' 
            size='normal' 
            class="custom-flag"/></li>
        </i>
      </nav>
    </div>
  </header>
</template>

<script>
  import CountryFlag from 'vue-country-flag';

  export default {
    name: "MenuComponent",
    components: {
      CountryFlag
    }
  };
</script>

<style scoped>
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    /* color: #006283; */
    color: white;
    font-weight: bold;
  }
  .navbar .getstarted, .navbar .getstarted:focus {
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    border: 2px solid #006283;
    font-weight: 600;
  }
  .navbar .getstarted:hover, .navbar .getstarted:focus:hover {
    color: #fff;
    background: #31a9e1;
  }
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 20px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    font-weight: 500;
    color: #0c3c53;
  }
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #006283;
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }

  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }

  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }

    .navbar ul {
      display: none;
    }
  }
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(40, 58, 90, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    
  }
  .no-bullets {
      list-style-type: none;
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #37517e;
  }
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #006283;
  }
  .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
    color: #37517e;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #006283;
  }
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  .custom-flag {
    cursor: pointer;
  }
</style>